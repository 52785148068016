<template>
  <header class="detail-header">
    <div class="navigation" v-if="source.indexOf('xcx') === -1">
      <div class="lt">
        <div class="back" @click="bindBack"></div>
        <div class="record" v-if="state.recordData.length > 0">
          <van-swipe :autoplay="3000" class="record-swiper" loop :show-indicators="false" vertical :touchable="false">
            <van-swipe-item class="item" v-for="(item ,index) in state.recordData" :key="index">
              <img :src="item.avatar" class="img">
              <span>{{item.buy_message}}</span>
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>
      <div class="rt">
        <div v-if="state.isMiniprogram" class="zhuan" @click="getCode"></div>
        <router-link to="/mine" class="option-item">
          <div class="mine">
            <i v-if="reciveDotShow" class="dot"></i>
          </div>
        </router-link>
        <div class="share" @click="bindShare"></div>
      </div>
    </div>
    <div class="banner" :class="{'banner-01' : courseData.type == '5'}">
      <img @load="onBannerLoaded" ref="banner" :src="courseData.thumb" class="ban-img" v-if="courseData.thumb">
      <p v-if="source.indexOf('xcx') !== -1 && courseData.platform == 8" class="cid" @click.stop="copy(courseData.code)">
        <span>{{courseData.code}}</span>
        <span class="icon-copy"></span>
      </p>
      <p v-if="source.indexOf('xcx') !== -1 && courseData.platform !== 8" class="cid" @click.stop="copy(courseData.course_id)">
        <span>{{courseData.course_id}}</span>
        <span class="icon-copy"></span>
      </p>
    </div>
    <van-popup :show="state.zhuanShow" @click-overlay="bindOverlay" @opened="onOpened">
      <div class="myCanvas-box">
        <canvas id="myCanvas" width="295" height="420" class="myCanvas" style="display: none;"></canvas>
      </div>
      <div class="save-btn" @click="downIamge()" v-if="state.load && state.browser == 2">点击按钮保存到相册</div>
      <div class="save-btn" @click="downIamge()" v-if="state.load && state.browser == 1">长按图片保存到相册</div>
    </van-popup>
  </header>
</template>
<script>
import {
  getCurrentInstance,
  watchEffect,
  reactive,
  onMounted,
  ref,
  computed
} from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import useClipboard from 'vue-clipboard3' // 调用复制
import wx from 'weixin-js-sdk'
export default {
  name: 'detail-header',
  props: {
    courseData: Object,
    dis_id: '',
    source: String
  },
  setup(props, context) {
    console.log('在哪儿里', window.location.href)
    const { proxy } = getCurrentInstance()
    const $router = useRouter()
    const store = useStore()
    const banner = ref(null)
    const state = reactive({
      recordData: [], //购买记录
      codeData: [], //分销数据
      zhuanShow: false,
      load: false,
      browser: '', //1-微信浏览器2-其他浏览器
      isMiniprogram: false // 是否是小程序
    })

    const reciveDotShow = computed(() => {
      return store.state.globalData.receive_id == -1
    })

    onMounted(() => {
      isWeiXin() //是否为微信浏览器
      isInMiniprogram()
    })

    watchEffect(() => {
      //  获取购买记录0推广 1拼团
      if (props.dis_id && props.courseData && 'mock_area' in props.courseData) {
        getRecord(2)
        return
      }
      if (
        props.dis_id &&
        props.courseData &&
        props.courseData.platform !== 4 &&
        props.courseData.platform !== 5
      ) {
        if (props.courseData.group_status == 1 && props.courseData.id) {
          getRecord(1)
        } else if (props.courseData.id) {
          getRecord(0)
        }
      }
    })

    // 复制课程id
    const { toClipboard } = useClipboard()
    const copy = async (Msg) => {
      try {
        //复制
        await toClipboard(Msg)
        proxy.$toast('复制成功')
      } catch (e) {
        //复制失败
        proxy.$toast('复制失败')
      }
    }

    //判断是否是微信浏览器的函数
    const isWeiXin = () => {
      //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
      var ua = window.navigator.userAgent.toLowerCase()
      //通过正则表达式匹配ua中是否含有MicroMessenger字符串
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        state.browser = '1'
      } else {
        state.browser = '2'
      }
    }

    // 判断是否在小程序中
    const isInMiniprogram = () => {
      var ua = navigator.userAgent.toLowerCase()
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            console.log('在小程序里')
            state.isMiniprogram = true
          } else {
            console.log('不在小程序里')
          }
        })
      } else {
        console.log('不在微信里')
      }
    }

    // 初始化滑动验证
    const onOpened = () => {
      doDraw()
    }

    // 获取购买记录
    const getRecord = (type) => {
      // type == 2为模考大赛的报名记录
      let _url = '/api/order/record/' + props.courseData.id + '/' + type
      if (type == 2) {
        _url = '/api/mock/record/' + props.courseData.id
      }
      console.log('_url:', _url)
      proxy.$http
        .get(_url)
        .then((res) => {
          console.log('购买记录===>', res.data)
          const body = res.data.data

          if (res.data.code == '200') {
            if (body !== null) {
              state.recordData = body
            }
          } else {
            proxy.$toast(res.data.message)
          }
        })
        .catch((error) => console.log(error))
    }

    // 获取分销
    const getCode = () => {
      proxy.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '加载中...'
      })
      proxy.$http
        .get('/api/course/qr_code/' + props.courseData.shop_id)
        .then((res) => {
          const body = res.data.data

          if (res.data.code == '200') {
            state.codeData = body
            state.zhuanShow = true
          } else {
            proxy.$toast(res.data.message)
          }
        })
        .catch((error) => console.log(error))
    }

    // 分享
    const bindShare = () => {
      context.emit('bindShare') //关闭弹窗
    }

    // 关闭弹窗
    const bindOverlay = () => {
      state.zhuanShow = false
      state.load = false
      $('.myCanvas-box>img').remove()
    }

    // 绘制图片
    const doDraw = () => {
      //获取Canvas对象(画布)
      const can = document.getElementById('myCanvas')
      //获取对应的CanvasRenderingContext2D对象(画笔)
      var can2d = can.getContext('2d')
      //创建新的图片对象
      var img = new Image()
      //指定图片的URL
      img.src =
        'https://file.huatu.com/static/miniprogram/shop/share_bg_tuiguang.png'
      img.setAttribute('crossOrigin', 'anonymous')
      //浏览器加载图片完毕后再绘制图片
      img.onload = function () {
        can2d.drawImage(img, 0, 0, 295, 420)
        var img01 = new Image()
        img01.src = state.codeData.qr_code_url
        img01.setAttribute('crossOrigin', 'anonymous')
        img01.onload = function () {
          can2d.drawImage(img01, 23, 330, 70, 70)
          //获取Canvas对象(画布)
          const can = document.getElementById('myCanvas')
          var img = convertCanvasToImage(can)
          $('.myCanvas-box').append(img)

          function convertCanvasToImage(canvas) {
            var image = new Image()
            image.src = canvas.toDataURL('image/png')
            state.load = true

            proxy.$toast.clear()
            return image
          }
        }
      }
    }

    const onBannerLoaded = () => {
      let bannerHeight = banner.value.offsetHeight
      $('.banner').css({
        height: bannerHeight + 'px'
      })
    }

    //下载海报
    const downIamge = () => {
      var img = $('.myCanvas-box').children('img').attr('src')
      var alink = document.createElement('a')
      alink.href = img
      alink.download = '华图小店.png'
      alink.click()
    }

    const bindBack = () => {
      $router.replace({
        name: 'index',
        params: {
          shop_id: props.courseData.shop_id
        }
      })
    }

    return {
      state,
      reciveDotShow,
      getRecord,
      getCode,
      bindShare,
      bindOverlay,
      downIamge,
      onOpened,
      bindBack,
      banner,
      onBannerLoaded,
      copy,
      isInMiniprogram
    }
  }
}
</script>

<style scoped lang="scss" src="../../views/detail/index.scss"></style>