import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from '@/axios/axios';
import libs from './utils/libs';
import 'lib-flexible/flexible';
import components from './components'; // icon
import vanTitle from './components/vanTitle/index.vue';
import vantEmpty from './components/vantEmpty/index.vue';
import Vant from 'vant';
import 'vant/lib/index.css'; // 全局引入样式
import eventBus from './utils/eventBus';
// import Vconsole from 'vconsole';
// import jweixinModule from "jweixin-module"
// import $ from 'jquery'

const app = createApp(App);
app
  .use(Vant)
  .use(store)
  .use(router)
  .component('vanTitle', vanTitle)
  .component('vantEmpty', vantEmpty)
  .mount('#app');
app.use(axios);
app.use(libs);
app.use(components);
app.use(eventBus);
// if (process.env.VUE_APP_API_ENV != 'prod') {
//   //正式环境不使用Vconsole
//   let vConsole = new Vconsole();
//   Vue.use(vConsole);
// }
app.config.isCustomElement = tag => tag === 'wx-open-launch-weapp';
