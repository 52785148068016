<template>
    <!-- 分享 -->
    <van-popup :show="shareShow" @click-overlay="bindSharePop" class="group-share-box">
        <img src="../../assets/image/detail/group_share.png" class="group-share" @click="bindSharePop" v-if="type == 'group'">
        <img src="../../assets/image/detail/share.png" class="group-share" @click="bindSharePop" v-else>
    </van-popup>
</template>

<script>
import { getCurrentInstance, onMounted, watchEffect, reactive} from "vue";
import useClipboard from 'vue-clipboard3';
import { sensorTrack } from '../../utils/util';
export default {
	name: "detail-header",
    props: {
        type: String,
        shareShow: Boolean
    },
	setup(props,context) {
        const { proxy } = getCurrentInstance();
        const state = reactive({
			shareShow: false,//分享弹窗
            browser: '',//1-微信浏览器2-其他浏览器
        });

        onMounted(() => {
            isWeiXin();
        })

        watchEffect(() => {
            if(props.shareShow){
                if(props.shareShow == true) {
                    sensorTrack({
                        eventName: 'HuaTuEducation_m_htxd_ShareCourse',
                        params: {
                            share_type: '',
                            share_url: window.location.href,
                            $url: window.location.href
                        }
                    });
                }
                if(props.shareShow == true && state.browser == 2){
                    copy(window.location.href);
                }
            }
        })

        // 复制链接
        const { toClipboard } = useClipboard()
        const copy = async (Msg) => {
            try {
                //复制
                await toClipboard(Msg)
                proxy.$toast("复制链接成功请分享给好友！");
                bindSharePop();
            } catch (e) {
                //复制失败
                proxy.$toast("复制失败");
            }
        }

        //判断是否是微信浏览器的函数
        const isWeiXin = () => {
            //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
            var ua = window.navigator.userAgent.toLowerCase();
            //通过正则表达式匹配ua中是否含有MicroMessenger字符串
            if(ua.match(/MicroMessenger/i) == 'micromessenger'){
                state.browser = '1';
            } else{
                state.browser = '2';
            }
        }
        
        // 分享弹窗
        const bindSharePop = () => {
            state.shareShow = !state.shareShow;
            context.emit('bindShare');//关闭弹窗
        }

		return {
            state,
            bindSharePop,
            isWeiXin
		}
	}
};
</script>

<style scoped lang="scss">

</style>