/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
 import axios from 'axios'
 import { setStore } from "../utils/util";
 import router from '../router/index'

 /**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
	router.push({
		path: '/login',
		query: {
			redirect: router.currentRoute._rawValue.fullPath
		}
	})
}

 // 创建axios实例
//  console.log(process.env.VUE_APP_BASE_URL);
 var host = window.location.host;
 var base_url = '';
 if(host == "localhost:8080") {
    base_url = "https://xdapi-test.huatu.com"
 } else if(host == "xd-test.huatu.com"){
    base_url = "https://xdapi-test.huatu.com"
 } else if(host == "xd-pre.huatu.com"){
 	base_url = "https://xdapi-pre.huatu.com"
 } else {
 	base_url = "https://xdapi.huatu.com"
 }
 console.log(host, base_url)
 const instance = axios.create({
	// baseURL: process.env.VUE_APP_BASE_URL,
	baseURL: base_url,
   	timeout: 1000 * 12
 })

 // 设置post请求头
 instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

 /**
  * 请求拦截器
  * 每次请求前，如果存在token则在请求头中携带token
  */
 instance.interceptors.request.use(
	config => {
		// 登录流程控制中，根据本地是否存在token判断用户的登录情况
		// 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
		// 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
		// 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
		// 部分接口不需要token
		const token = localStorage.getItem("access_token")
		token && (config.headers.Authorization = token)
		return config
	},
	error => Promise.error(error))
 
 // 响应拦截器
 instance.interceptors.response.use(
	// 请求成功
	response => {
		var token = response.headers.authorization
	
		if (token) {// 如果 header 中存在 token，那么触发 refreshToken 方法，替换本地的 token
			localStorage.setItem('access_token',token)
		}
		
		// 统一接口状态判断
		if(response.data.code === 401){
			setStore('userInfo', '')
			setStore('access_token', '')
			toLogin()//token过期
		} 

		return Promise.resolve(response)
	},
	// 请求失败
	error => {
		const { response } = error;
		if (response) {
			// 请求已发出，但是不在2xx的范围
			console.log("接口报错状态===》", response.status, response.data.message)
			return Promise.reject(response)
		} else {
			// 处理断网的情况
			// eg:请求超时或断网时，更新state的network状态
			// network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
			// 关于断网组件中的刷新重新获取数据，会在断网组件中说明
			//   store.commit('changeNetwork', false);
		}
   })
 
 export default {
	install(app) {
		app.config.globalProperties.$http = instance
	}
 };

