<template>
  <div class="detail-footer">
    <div class="station"></div>
    <footer class="footer" v-if="courseData">
      <!-- 判断课程未下线未购买 -->
      <template v-if="!is_mock && courseData.course_status == 2 && !buyData.is_buy">
        <template v-if="courseData.group_status == 1">
          <!-- 拼团课程未开始 -->
          <div class="footer-count" :class="courseData.flag == 3 ? 'spike-hide' : '' " v-if="groupData.group_start_time > 0 && courseData.group_status == 1 && !state.buy_start">
            <div class="lt">
              <p>距开始</p>
              <van-count-down :time="groupData.group_start_time" format="DD 天 HH 时 mm 分 ss 秒" @finish="bindFinishPt" />
            </div>
          </div>
          <!-- 拼团进倒计时 -->
          <div class="footer-count footer-count-01" v-if="groupData !== '' && groupData.group_end_time > 0 && courseData.group_status == 1 && gu_id !== '0'" @click="toGroupDetail(gu_id)">
            <div class="lt">
              <p>还差{{state.user_num}}人拼团成功 剩余</p>
              <van-count-down :time="groupData.group_end_time" fßormat="DD 天 HH:mm:ss " />
            </div>
            <div class="rt">
              <div class="head-img">
                <img :src="item.avatar" v-for="(item ,index) in groupData.join_user" :key="item + index" class="img">
              </div>
              <i class="icon-right"></i>
            </div>
          </div>
        </template>
        <template v-else-if="courseData.status == 1">
          <!-- 普通课程倒计时 -->
          <div class="footer-count" :class="courseData.flag == 3 ? 'spike-hide' : '' " v-show="!state.is_start && !state.is_end && courseData.sale_start_time > 0">
            <p>距开始还有</p>
            <van-count-down :time="courseData.sale_start_time" format="DD 天 HH 时 mm 分 ss 秒" @finish="bindFinishStart" />
          </div>
          <div class="footer-count" :class="courseData.flag == 3 ? 'spike-hide' : '' " v-show="state.is_start && !state.is_end && courseData.sale_end_time > 0">
            <p>距结束还有</p>
            <van-count-down :time="courseData.sale_end_time" format="DD 天 HH 时 mm 分 ss 秒" @finish="bindFinishEnd" />
          </div>
        </template>
      </template>

      <div class="footer-bt">
        <div class="lt">
          <button class="item" @click="bindToHome">
            <i class="icon-shop"></i>
            <span>店铺</span>
          </button>
          <!-- 教育客服 -->
          <button class="item kefu" :class="courseData.id && 'kefu_' + courseData.id" v-if="courseData.reception_id !== 0 && state.kefuData.number !== '' && (courseData.platform !== 2 )">
            <i class="icon-kefu"></i>
            <span>客服</span>
          </button>
          <!-- 在线售前客服 -->
          <button class="item" v-if="courseData.platform == 2 " @click="bindKefu()">
            <i class="icon-kefu"></i>
            <span>客服</span>
          </button>
        </div>
        <div class="rt">
          <template v-if="!is_spike && !is_mock && courseData.course_status == 2">
            <!-- 普通课程 -->
            <template v-if="courseData.status == 1 && courseData.group_status !== 1">
              <!-- 课程已过期 -->
              <template v-if="state.is_end">
                <div class="btn btn-01">已下线</div>
              </template>
              <!-- 不限购or有库存 -->
              <template v-else-if="courseData.limit_num == 0 || courseData.limit_num - courseData.buy_num > 0">
                <div class="btn btn-01" v-show="!state.is_start">未开售</div>
                <div class="btn" @click="onShowReceivePop(courseData.id)" v-if="state.is_start && !buyData.is_buy && is_receive && !had_received">立即领取</div>
                <div class="btn" @click="bindBuyPop" v-else-if="state.is_start && (buyData.is_buy || (is_receive && if_current_receive(courseData.id)))">
                  <div v-if="HjcourseData.goods_source == 'BK'" @click="bindBuy(0)">
                    立即购买
                  </div>
                  <div v-else> 立即学习</div>
                  <!-- 立即学习 -->
                </div>
                <div class="btn" @click="bindBuy(0)" v-else-if="state.is_start && (!buyData.is_buy || (is_receive && had_received && !if_current_receive(courseData.id)))">立即购买</div>

              </template>
              <template v-else>
                <div class="btn btn-01">已售罄</div>
              </template>
            </template>
            <!-- 拼团课程 -->
            <template v-if="courseData.group_status == 1">
              <!-- 是否购买 -->
              <template v-if="buyData.is_buy || (is_receive && if_current_receive(courseData.id))">
                <div class="btn" @click="bindBuyPop">
                  <span class="tit">立即学习</span>
                </div>
              </template>
              <!-- 是否库存充足 -->
              <template v-else-if="courseData.limit_num !== 0 && courseData.limit_num - courseData.buy_num <= 0">
                <div class="btn btn-01">已售罄</div>
              </template>
              <template v-else-if="is_receive && !had_received">
                <div class="btn" @click="onShowReceivePop(courseData.id)">立即领取</div>
              </template>
              <template v-else>
                <div class="btn-small btn-small-no" @click="bindBuy(0)">
                  <span class="txt">¥{{courseData.price}}</span>
                  <span class="tit">任性单买</span>
                </div>
                <!-- 库存数是否大于拼团人数 -->
                <template v-if="courseData.limit_num !== 0 && courseData.limit_num - courseData.buy_num < groupData.limit_number && !groupData.is_join">
                  <div class="btn-small btn-small-gray">库存不足</div>
                </template>
                <!-- 是否有团ID -->
                <template v-else-if="gu_id !== '0'">
                  <div class="btn-small btn-small-on" v-if="!groupData.is_join" @click="bindBuy(1)">
                    <span class="txt">¥{{groupData.group_price}}</span>
                    <span class="tit">立即参团</span>
                  </div>
                  <div class="btn-small btn-small-on" @click="toGroupDetailShare(gu_id)" v-if="groupData.is_join">
                    <span class="txt">¥{{groupData.group_price}}</span>
                    <span class="tit">参团中，去邀请</span>
                  </div>
                </template>
                <template v-else>
                  <div class="btn-small btn-small-on" @click="bindSharePop" v-if="groupData.joined_other_pid">
                    <span class="txt">¥{{groupData.group_price}}</span>
                    <span class="tit">发起拼团</span>
                  </div>
                  <div class="btn-small" :class="{'btn-small-gray':!state.buy_start,'btn-small-on':state.buy_start}" @click="bindBuy(1)" v-else>
                    <span class="txt">¥{{groupData.group_price}}</span>
                    <span class="tit">发起拼团</span>
                  </div>
                </template>
              </template>
            </template>
            <template v-if="courseData.status !== 1 && courseData.group_status !== 1">
              <div class="btn btn-01">已下线</div>
            </template>
          </template>
          <template v-else-if="is_mock">
            <button :disabled="courseData.is_end == 1" class="btn" :class="[courseData.is_end == 1 ? 'btn-01':'']" @click="bindRegister">{{courseData.is_end == 1 ? '活动已结束' : (courseData.is_register ? (courseData.subject_id == 8 ? '报名成功,点击此处可更改机构性质' : '报名成功,点击此处可更改地区') : '我要报名')}}</button>
          </template>
          <template v-else-if="is_spike">
            <div class="btn" @click="bindBuyPop" v-if="buyData.is_buy">立即学习</div>
            <!-- 秒杀未开始 -->
            <template v-else-if="!state.is_start">
              <div class="btn-small btn-small-no" @click="bindBuy(3)">
                <span class="txt">¥{{courseData.origin_price}}</span>
                <span class="tit">立即购买</span>
              </div>
              <div class="btn-small btn-small-gray">
                <span class="txt">¥{{courseData.price}}</span>
                <span class="tit">秒杀未开始</span>
              </div>
            </template>
            <!-- 秒杀进行中 -->
            <template v-else-if="!state.is_end">
              <div class="btn column" @click="bindBuy(3)">
                <span class="txt">¥{{courseData.price}}</span>
                <span class="tit">立即购买</span>
              </div>
            </template>
            <!-- 秒杀已结束 -->
            <template v-else>
              <div class="btn column" @click="bindBuy(3)">
                <span class="txt">¥{{courseData.origin_price}}</span>
                <span class="tit">立即购买</span>
              </div>
            </template>
          </template>
          <template v-else>
            <div class="btn" @click="bindBuyPop" v-if="buyData.is_buy">立即学习</div>
            <div class="btn btn-01" v-else>已下线</div>
          </template>
        </div>
      </div>
    </footer>
    <a class="wechat-union-bar" v-if="unionBarShow" @click="clickUnionBar">
      <div class="desc">
        <div>该网页需获取个人信息才可使用完整</div>
        <div>服务，当前仅可浏览部分内容 </div>
      </div>
      <div class="nav">使用完整服务 &gt;</div>
    </a>
    <!-- 购买须知 -->
    <buyNotice :show="state.buyShow" :isBuy="buyData.is_buy" @bindBuyPop="bindBuyPop" :courseData="courseData"></buyNotice>
    <!-- 领取课程弹窗 -->
    <confirmPop :show="state.receivePopShow" :course="courseData" @onClosePop="onClosePop"></confirmPop>
  </div>
</template>

<script>
import {
  getCurrentInstance,
  onMounted,
  watchEffect,
  reactive,
  ref,
  computed
} from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { getStore, dedupe, sensorTrack } from '../../utils/util'
import { useStore } from 'vuex'
import buyNotice from '../../components/detail/buynotice.vue'
import confirmPop from '../../components/confirmPop/index.vue'
import md5 from 'js-md5'
export default {
  name: 'detail-footer',
  components: {
    buyNotice,
    confirmPop
  },
  props: {
    courseData: Object,
    groupData: Object,
    buyData: Object,
    gu_id: String,
    userInfo: '',
    dis_id: '',
    HjcourseData: Object
  },
  setup(props, context) {
    const { proxy } = getCurrentInstance()
    const $router = useRouter()
    const $route = useRoute()
    const store = useStore()
    const state = reactive({
      kefuData: [], //客服参数
      userInfo: '', //用户信息
      user_num: 0, //还差参团人数
      buyShow: false, //购买须知弹窗
      Countdown: false, //倒计时是否结束
      is_start: false, //普通课程开售倒计时
      is_end: false, //普通课程结束倒计时
      receivePopShow: false,
      spikeStatus: 0, // 秒杀状态（未开始0，进行中1，已结束2）
      unionId: '', //用户的unionid
      broswer: '' //1-微信浏览器2-其他浏览器
    })
    const unionBarShow = ref(false)
    let tmpReception_id

    const is_mock = computed(() => {
      if (props.courseData != '') {
        return 'mock_range' in props.courseData
      }
    })

    // 判断是否为秒杀
    const is_spike = computed(() => {
      return props.courseData.flag == 3
    })

    // 判断是否为领取课程
    const is_receive = computed(() => {
      return props.courseData.preview_flag == 1
    })

    // 判断该用户是否已经领取了课程
    const had_received = computed(() => {
      return store.state.globalData.receive_id != -1
    })

    // 判断用户领取的课程是否为传入id的课程
    const if_current_receive = computed(() => {
      return (cId) => {
        return (
          store.state.globalData.receive_id != -1 &&
          store.state.globalData.receive_id == cId
        )
      }
    })

    watchEffect(() => {
      if (
        props.courseData.reception_id &&
        props.courseData.reception_id !== tmpReception_id
      ) {
        getKefu() //获取客服信息
        tmpReception_id = props.courseData.reception_id
      }
      // 用户信息监听
      if (store.state.userInfo.userInfo) {
        state.userInfo = store.state.userInfo.userInfo
      }

      // 参团人数
      if (props.groupData) {
        if (props.groupData.join_user) {
          state.user_num =
            props.groupData.limit_number - props.groupData.join_user.length
        }
      }

      // 判断课程是否开售
      if (
        props.courseData.group_status !== undefined &&
        props.courseData.group_status == 1 &&
        !state.Countdown
      ) {
        if (props.groupData.group_start_time > 0) {
          state.buy_start = false
        } else {
          state.buy_start = true
        }
      }

      //普通课程开售时间
      console.log(props.courseData)
      if (
        props.courseData.sale_start_time !== undefined &&
        props.courseData.sale_start_time > 0
      ) {
        state.is_start = false
        state.is_end = false
        // console.log("课程未开售")
      } else if (
        props.courseData.sale_end_time !== undefined &&
        props.courseData.sale_start_time < 0 &&
        props.courseData.sale_end_time > 0
      ) {
        state.is_start = true
        state.is_end = false
        // console.log("课程已开售")
      } else if (
        props.courseData.sale_start_time !== undefined &&
        props.courseData.sale_end_time !== undefined &&
        props.courseData.sale_start_time < 0 &&
        props.courseData.sale_end_time < 0
      ) {
        state.is_start = true
        state.is_end = true
        // console.log("课程已过期")
      } else if (
        (props.courseData.sale_start_time == null &&
          props.courseData.sale_start_time !== undefined) ||
        (props.courseData.sale_end_time == null &&
          props.courseData.sale_end_time !== undefined)
      ) {
        state.is_start = true
        state.is_end = false
        // console.log("课程无活动时间")
      }
    })

    // 判断秒杀结束还是未开始（包括进行中）
    watchEffect(() => {
      let cData = props.courseData
      if (cData && cData.sale_start_time) {
        if (cData.sale_start_time > 0) {
          // 还没开始
          state.spikeStatus = 0
        } else if (cData.sale_end_time > 0) {
          // 进行中
          state.spikeStatus = 1
        } else {
          state.spikeStatus = 2
        }
      }
    })

    onMounted(() => {
      let userInfo = getStore('userInfo')

      if (userInfo) {
        store.commit('userInfo/addUser', userInfo)
        state.userInfo = userInfo
      } else {
        state.userInfo = ''
      }
      state.unionId = localStorage.getItem('htjy_unionId') || ''
      isWeiXin() //是否为微信浏览器
      if (
        state.broswer == '1' &&
        (!state.unionId || state.unionId == '-1') &&
        getUrlParam('code')
      ) {
        //微信浏览器中，有code，无有效的unionId
        //通过code获取unionId
        let code = getUrlParam('code')
        console.log('getUrlParam-code', code)
        let data = {
          huatuId: state.userInfo.huatuId,
          code: code,
          mobile: state.userInfo.mobile
        }
        proxy.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: '加载中...'
        })
        proxy.$http
          .post('/api/auth/xd_union_id', data)
          .then((res) => {
            console.log('xd_union_id', res)
            proxy.$toast.clear()
            if (res.data.code == '200') {
              localStorage.setItem('htjy_unionId', res.data.data.unionid)
              state.unionId = res.data.data.unionid
            } else {
              proxy.$toast(res.data.message)
            }
          })
          .catch((error) => {
            console.log(error)
            proxy.$toast('授权失败')
          })
      }
    })

    /**
     * 跳转登录页
     */
    const toLogin = () => {
      $router.push({
        path: '/login'
      })
    }

    // 购买须知
    const bindBuyPop = () => {
      if (is_receive === true) {
        state.receivePopShow = !state.receivePopShow
      } else {
        state.buyShow = !state.buyShow
      }
    }

    /**
     * flag 0-推广订单1-拼团订单
     */
    const bindBuy = (flag) => {
      console.log('state', flag, state)
      if (!state.buy_start && props.courseData.group_status == 1 && flag == 1) {
        proxy.$toast('活动未开始')
      } else if (!state.userInfo.name) {
        toLogin() //授权
      } else if (state.broswer == '1') {
        //微信环境下
        if (state.unionId && state.unionId != '-1') {
          //已登陆 有unionid 进行下一步
          bindBuyNext(flag)
        } else if (!state.unionId) {
          //如果已登陆 没有unionId（针对老数据，已登陆的用户 登陆时没有unionId）
          //清空登录信息，跳转登陆页
          localStorage.setItem('access_token', '')
          localStorage.setItem('userInfo', '')
          toLogin() //授权
        } else if (state.unionId == '-1') {
          //如果已登陆 没有unionId（已登陆的用户 登陆时返回了unionId，但是unionId为空 未绑定）
          //打开授权弹窗
          unionBarShow.value = true
        }
      } else {
        bindBuyNext(flag)
      }
    }

    /**
     * flag 0-推广订单1-拼团订单
     */
    const bindBuyNext = (flag) => {
      let userInfovalue = state.userInfo
      console.log('userInfovalue推广订单1-拼团订单', userInfovalue)
      //已登录并点击立即购买后上报huatu_id、union_id、huatu_code
      sa.login(userInfovalue.mobile) // 成功后上报手机号
      sa.setOnceProfile({
        // huatu_id: userInfovalue.huatuId,
        huatu_code: userInfovalue.huatuCode,
        huatu_edu_union_id: userInfovalue.unionId
      })
      if (
        (!state.is_start && is_spike.value) ||
        (state.is_end && is_spike.value)
      ) {
        flag = 0
      }
      proxy.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '加载中...'
      })
      sensorTrack({
        eventName: 'HuaTuEducation_m_htxd_PurchaseCourese',
        params: {
          course_id: props.courseData.id,
          course_title: props.courseData.title,
          course_source: props.courseData.platform,
          teach_way: props.courseData.type,
          buy_type: props.courseData.group_status == 1 ? '拼团' : '普通',
          price: props.courseData.price,
          course_collage_price:
            props.courseData.group_status == 1
              ? props.groupData.group_price
              : 0,
          $url: window.location.href,
          business_unit: props.courseData.department
        }
      }).then((res) => {
        console.log('res---------≠', res)
        $router.push({
          path: '/confirm',
          query: {
            shop_id: props.courseData.shop_id,
            id: props.courseData.id,
            flag: flag || '0',
            group_id: props.groupData.id,
            gu_user_id: props.gu_id
          }
        })
      })
    }

    //跳转授权页
    const clickUnionBar = () => {
      let appid = 'wxb6ffc17b892a2a7b'
      let local = window.location.href
      let time = Date.parse(new Date())
      let php_time = time.toString().substring(0, 10)
      let secret = 'getWxCodeFor_purchase.huatu.com'
      let signNew = md5(md5(local + php_time + secret))
      window.location.href =
        'https://m.xue.huatu.com/mp/proxy.php?appid=' +
        appid +
        '&time=' +
        php_time +
        '&sign=' +
        signNew +
        '&redirect_url=' +
        encodeURIComponent(local) +
        '&share=xd'
    }

    // 获取url code参数
    const getUrlParam = (name) => {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      let url = window.location.href.split('#')[0]
      let search = url.split('?')[1]
      if (search) {
        var r = search.substr(0).match(reg)
        if (r !== null) return unescape(r[2])
        return null
      } else {
        return null
      }
    }

    // 跳转拼团详情页
    const toGroupDetail = (id) => {
      $router.push({
        path: '/groupDetail',
        query: {
          gu_id: id,
          source: 'detail'
        }
      })
    }

    // 跳转拼团详情页展示分享弹窗
    const toGroupDetailShare = (id) => {
      store.commit('globalData/addShare', true) //全局参数
      $router.push({
        path: '/groupDetail',
        query: {
          gu_id: id,
          source: 'detail'
        }
      })
    }

    // 分享弹窗
    const bindSharePop = () => {
      if (props.groupData.joined_other_pid) {
        proxy.$dialog
          .confirm({
            title: '您已参加本课程拼团是否跳转？',
            confirmButtonColor: '#FC6C21'
          })
          .then(() => {
            toGroupDetail(props.groupData.joined_other_pid)
          })
          .catch(() => {
            // on cancel
          })
      } else {
        context.emit('bindSharePop')
      }
    }

    const htSobotInitFunc = (el, data) => {
      let { group_status, origin_price, price } = props.courseData
      let { group_price } = props.groupData
      let showPrice = price
      // 确定页面上当前展示的价格
      if (is_spike.value && group_status !== 1) {
        showPrice = state.spikeStatus == 2 ? origin_price : price
      } else {
        if (group_status === 1) {
          showPrice = group_price
        }
      }
      showPrice = '￥' + showPrice
      let card = {
        card_title: props.courseData.title,
        card_url: encodeURI(window.location.href),
        // card_desc:"云客服,在线客服系统,云呼叫中心,机器人客服,工单客服,智能客服",
        card_note: showPrice,
        card_picture: encodeURI(props.courseData.thumb)
      }
      htSobot.init(el, Object.assign(data, { card }))
    }

    // 获取客服参数
    const getKefu = () => {
      proxy.$http
        .get('/api/course/consult/' + props.courseData.reception_id)
        .then((res) => {
          const body = res.data.data

          if (res.data.code == '200') {
            state.kefuData = body

            if (state.kefuData.number) {
              // htSobotInitFunc('kefu_' + props.courseData.id, {
              //   //写死宋丽深老师的智齿id，打开的聊天窗口直接是宋老师，不需要客服再转接
              //   service: 'zhichi', //分校分部
              //   zhichi_id: '006f22534fd543ac987bde1d466df18d',
              //   type: 'js',
              //   new_window: false,
              //   prod: true
              // })

              if (state.kefuData.type == '0') {
                htSobotInitFunc('kefu_' + props.courseData.id, {
                  service: 'branch', //分校分部
                  ehr_code: state.kefuData.number,
                  type: 'js',
                  new_window: false,
                  prod: true
                })
              } else if (state.kefuData.type == '1') {
                htSobotInitFunc('kefu_' + props.courseData.id, {
                  service: 'business', //事业部
                  sys_id: state.kefuData.number,
                  type: 'js',
                  new_window: false,
                  prod: true
                })
              } else if (state.kefuData.type == '2') {
                htSobotInitFunc('kefu_' + props.courseData.id, {
                  service: 'channel', //渠道
                  sys_id: state.kefuData.number,
                  type: 'js',
                  new_window: false,
                  prod: true
                })
              }
            }
          } else {
            proxy.$toast(body.message)
          }
        })
        .catch((error) => console.log(error))
    }

    // 在线客服
    const bindKefu = () => {
      let url = ''
      let title_info = props.courseData.title
      let label_info =
        props.courseData.group_status == 1
          ? props.groupData.group_price
          : props.courseData.price
      let url_info = encodeURIComponent(window.location.href)
      let tel = props.userInfo.mobile
      let huatuCode = props.userInfo.huatuCode
      // 区分售前售后客服
      if (!props.buyData.is_buy) {
        console.log('1111-5')
        url =
          'https://huatu.sobot.com/chat/h5/v2/index.html?sysnum=c1a2757af422403fbce73e24dccc5160&channelid=21&groupid=b698f966107a4e6bbedb371a4ebb6385&title_info=' +
          title_info +
          '&label_info=' +
          label_info +
          '&url_info=' +
          url_info +
          '&tel=' +
          tel +
          '&type=4&robotid=6' +
          '&params=' +
          encodeURI('{"huatuCode":"' + huatuCode + '"}')
      } else {
        console.log('1111-6')
        url =
          'https://huatu.sobot.com/chat/h5/v2/index.html?sysnum=c1a2757af422403fbce73e24dccc5160&channelid=21&groupid=0cab09278fd649c4817beae840be1b5d&title_info=' +
          title_info +
          '&label_info=' +
          label_info +
          '&url_info=' +
          url_info +
          '&tel=' +
          tel +
          '&type=3&robotid=6' +
          '&params=' +
          encodeURI('{"huatuCode":"' + huatuCode + '"}')
      }

      // 跳转客服
      // console.log(url)
      window.location.href = url
    }

    // 跳转店铺
    const bindToHome = () => {
      $router.push({
        name: 'index',
        params: {
          shop_id: props.courseData.shop_id
        }
      })
    }

    // 普通倒计时结束
    const bindFinishStart = () => {
      state.buy_start = 1
      state.is_start = true
      // console.log("普通课程开售倒计时结束",state.buy_start)
    }
    const bindFinishEnd = () => {
      // 判断当前课程有活动时间
      if (
        props.courseData.sale_start_time !== null &&
        props.courseData.sale_end_time !== null
      ) {
        state.buy_start = 2
        state.is_end = true
        // console.log("普通课程过期倒计时结束",state.buy_start)
      }
    }

    // 拼团倒计时结束
    const bindFinishPt = () => {
      state.Countdown = true
      state.buy_start = true
    }

    // 模考大赛
    const bindRegister = () => {
      context.emit('bindMockRegister')
    }

    // 点击了立即领取
    const onShowReceivePop = (dis_id) => {
      console.log('我要领取课程:', dis_id)
      state.receivePopShow = true
    }

    // 领取课程弹窗关闭事件
    const onClosePop = (flag_hasReceive) => {
      state.receivePopShow = false
      if (flag_hasReceive) {
        // 通知detail页面，重新加载课程data
        emit('refreshData')
      }
    }

    //判断是否是微信浏览器的函数
    const isWeiXin = () => {
      //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
      var ua = window.navigator.userAgent.toLowerCase()
      //通过正则表达式匹配ua中是否含有MicroMessenger字符串
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        state.broswer = '1'
      } else {
        state.broswer = '2'
      }
    }

    return {
      state,
      toGroupDetail,
      getKefu,
      bindBuy,
      bindToHome,
      bindSharePop,
      toLogin,
      bindBuyPop,
      bindFinishStart,
      bindFinishPt,
      bindFinishEnd,
      toGroupDetailShare,
      bindKefu,
      bindRegister,
      is_mock,
      is_spike,
      is_receive,
      had_received,
      if_current_receive,
      onShowReceivePop,
      onClosePop,
      unionBarShow,
      clickUnionBar,
      getUrlParam
    }
  }
}
</script>

<style scoped lang="scss" src="../../views/detail/index.scss"></style>
<style scoped>
.wechat-union-bar {
  width: 100vmin;
  height: 24vmin;
  background: #000000;
  position: fixed;
  bottom: 0;
  left: 50%;
  margin-left: -50vmin;
  display: flex;
  justify-content: space-between;
  padding: 4.3vmin;
  box-sizing: border-box;
  z-index: 11;
}
.wechat-union-bar .desc {
  font-size: 3.2vmin;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.5;
}
.wechat-union-bar .nav {
  font-size: 3.7vmin;
  font-weight: 400;
  color: #ffffff;
}
</style>


