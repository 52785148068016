<template>
  <van-count-down :time="time" class="countdown" @finish="onFinish">
    <template #default="timeData">
        <div class="block dd">
            <span class="first">{{splitLetter(timeData.days)[0]}}</span>
            <span class="second">{{splitLetter(timeData.days)[1]}}</span>
        </div>
        <div class="block hh">
            <span class="first">{{splitLetter(timeData.hours)[0]}}</span>
            <span class="second">{{splitLetter(timeData.hours)[1]}}</span>
        </div>
        <div class="block mm">
            <span class="first">{{splitLetter(timeData.minutes)[0]}}</span>
            <span class="second">{{splitLetter(timeData.minutes)[1]}}</span>
        </div>
        <div class="block ss">
            <span class="first">{{splitLetter(timeData.seconds)[0]}}</span>
            <span class="second">{{splitLetter(timeData.seconds)[1]}}</span>
        </div>
    </template>
</van-count-down>
</template>

<script>
import {
    formmateNumToDoubleString
} from '../../utils/util.js';
export default {
    name: 'countDown',
    props: {
        time: Number
    },
    setup(props, { emit }) {
        const splitLetter = (num) => {
            num = formmateNumToDoubleString(num);
            return num.split('');
        }
        const onFinish = () => {
            emit('bindFinished');
        }
        return {
            splitLetter,
            onFinish
        }
    }
}
</script>

<style lang="scss" scoped>
    .countdown{
        background: url(https://file.huatu.com/static/miniprogram/shop/h5/detail/icon-countdown-outside2@2x.png) no-repeat 0 0;
        background-size: cover;
        width: 171px;
        height: 20px;
        margin-top: 6px;
        display: flex;
        align-items: center;
        .block{
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            position: relative;
            .first{
                position: absolute;
                left: 3px;
                top: 50%;
                transform: translateY(-50%);
            }
            .second{
                position: absolute;
                left: 21px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .dd {
            margin-left: 0;
        }
        .hh {
            margin-left: 50px;
        }
        .mm {
            margin-left: 45px;
        }
        .ss {
            margin-left: 44px;
        }
    }
</style>