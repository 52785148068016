<template>
    <section id="buy-notice">
        <!-- 购买须知 -->
        <van-popup :show="show" @click-overlay="bindBuyPop" round>
             <!-- 中师神2 start -->
             <div class="buy-box buy-box03" v-if=" platform== 6 || platform== 7">
                <div class="content content03">
                    <img src="https://file.huatu.com/static/miniprogram/shop/h5/detail/bg-zhongshishen2.png" class="img">
                </div>
            </div>
            <!-- 中师神2 end -->
            
            <div v-else class="buy-box" :class="{'buy-box01' : (platform == 2 || platform == 8),'buy-box02' : (platform == 2 || platform == 8) && !state.is_buy && state.qrcode}">
                <div class="content" :class="{ 'content01': state.browser == 2 || (source&&source.indexOf('xcx') !== -1), 'content02' : platform == 2}">
                    <!-- 华图在线 start -->
                    <template v-if="platform == 2 || platform == 8">
                        <img v-if="!state.qrcode" src="https://file.huatu.com/static/miniprogram/shop/h5/detail/bg-buy-online.png" class="img">
                        <template v-if="state.qrcode">
                            <img src="https://file.huatu.com/static/miniprogram/shop/h5/detail/bg-buy-online2.png" class="img">
                            <div class="teacher-qrcode" >
                                <img :src="state.qrcode">
                            </div>
                        </template>
                    </template>
                    <!-- 华图在线 end -->
                    <!-- 华图教育 start -->
                    <img v-if="platform != 2 && platform != 8" src="https://file.huatu.com/static/miniprogram/shop/h5/detail/bg-buy.png" class="img">
                    <!-- 华图教育 end -->
                    <template v-if="platform != 2 && platform != 8">
                        <div class="box-ewm" @click="downIamge">
                            <img src="../../assets/image/detail/ewm_htjy.jpg" class="ewmimg">
                            <div class="btn-ewm" v-if="state.browser == 2">保存二维码</div>
                            <div class="btn-ewm" v-else>长按识别小程序</div>
                        </div>
                        <div class="box-htjy">
                            <wx-open-launch-weapp id="launch-btn" username="gh_ee2aa0afb102" path="pages/home/home.html" style="width: 100%;height:100%;display:block;">
                                <div v-is="'script'" type="text/wxtag-template">
                                    <img src="https://file.huatu.com/static/miniprogram/shop/bg_jump_htjy.png" style="width: 100%;">
                                </div>
                            </wx-open-launch-weapp>
                        </div>
                    </template>
                </div>
            </div>
        </van-popup>
    </section>
</template>
<script>
import { getCurrentInstance, onMounted, watchEffect, reactive, computed, watch} from "vue";
export default {
	name: "buy-notice",
    props: {
        show: '',
        source: String,
        courseData: Object,
        orderInfo: Object,
        isBuy:Boolean
    },
	setup(props,context) {
        const { proxy } = getCurrentInstance();
        const state = reactive({
            browser: '',//1-微信浏览器2-其他浏览器,
            // courseid:'', // 课程ID，请求在线助教二维码使用
             qrcode : '',
            // qrcode : 'https://file.huatu.com/static/miniprogram/shop/h5/detail/hai.png' , // 请求在线助教二维码使用
            is_buy:false // 是否购买当前课程
        });

        const platform = computed(() => {
            console.log('isBuy',props.isBuy)
            state.is_buy=props.isBuy;
            if(props.courseData || props.orderInfo) {
                let obj = props.courseData || props.orderInfo;

                let id = props.courseData ? props.courseData.platform : props.orderInfo.platform;

                if(id == 2){
                    proxy.$http.get("/api/course/pd_division_code",{
                        params: {
                            courseId:  obj.courseid,
                            goodsId: obj.goodsid
                        }
                    })
                    .then(res => {
                      
                        let item =  res.data;
                          console.log(item);
                        if(item.code==200){
                            state.qrcode = item.data.url;
                        }
                        console.log('state.qrcode',state.qrcode)
                    })
                }
                return id;
            }
            return 1;
        })



       

        onMounted(() => {
            isWeiXin();//是否为微信浏览器
        })

        // 购买须知
        const bindBuyPop = () => {
            context.emit('bindBuyPop');
        }

        //下载二维码
        const downIamge = () => {
            var img = $('.box-ewm').children('img').attr("src");
            var alink = document.createElement("a");
            alink.href = img;
            alink.download = "华图教育.png";
            alink.click();
        }

        //判断是否是微信浏览器的函数
        const isWeiXin = () => {
            //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
            var ua = window.navigator.userAgent.toLowerCase();
            //通过正则表达式匹配ua中是否含有MicroMessenger字符串
            if(ua.match(/MicroMessenger/i) == 'micromessenger'){
                state.browser = '1';
            } else {
                state.browser = '2';
            }
        }

		return {
            state,
            platform,
            bindBuyPop,
            downIamge,
            isWeiXin
		}
	}
};
</script>

<style scoped lang="scss">
.buy-box{
    width: 310px;
    height: 520px;
    .content{
        width: 100%;
        height: 720px;
        position: relative;
        overflow: hidden;
        .img{
            width: 100%;
            height: auto;
            position: absolute;
            top: 0;
        }
        .box-ewm{
            width: 160px;
            height: 100px;
            position: absolute;
            top: 450px;
            left: 75px;
            z-index:15px;
            .ewmimg{
                width: 100px;
                height: 100px;
                margin-left: 30px;
            }
            .btn-ewm{
                font-size: 13px;
                color: #FC6C21;
                font-weight: bold;
                line-height: 18px;
                text-align: center;
            }
        }
        .box-htjy{
            width: 245px;
            height: 68px;
            position: absolute;
            top: 639px;
            left: 27px;
        }
    }
    .content01{
        height: 575px;
    }
    .content02{
        height: 560px;
    }
    .content03{
        height: 481px;
    }
}
.buy-box03{
    height: 481px !important;
}
.buy-box01{
    height: 560px;
}
.buy-box02{
    height: 410px;
    overflow: hidden;
}
.teacher-qrcode{
    position: absolute;
    width: 106px;
    height: 106px;
    bottom: 10px;
    left: calc(50% - 50px);
    border-radius: 5px;
    border: 6px solid #fff;
}
.teacher-qrcode img{
    width:100%;
    height: 100%;
}
</style>