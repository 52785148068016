<template>
  <van-popup class="mock-pop" :show="show" :style="{ width: '100%', background: 'transparent' }">
        <div class="pop-content">
            <div class="header" :class="[!isRegister ? 'notRegister' : '']">
                <img :class="[!isRegister ? 'noRegs' : '']" src="https://file.huatu.com/static/miniprogram/shop/h5/mock/icon-mock-pop-circle.png" alt="">
                <div class="title" :class="[isRegister ? 'success' : (mockData.subject_id == 8 ? 'agency' : 'area')]"></div>
                <div class="subTitle">{{ isRegister ? '同学您好，您可通过以下方式进行考试哦！' : '填写信息可获得更精准的模考报告' }}</div>
            </div>
            <div class="body" :style="{'margin-top': (!isRegister ? '-9px' : '-24px'), 'background' : (!isRegister ? '#fff' : '#FFF4ED')}">
                <!-- 省考 -->
                <template v-if="!isRegister && mockData.subject_id != 8">
                    <div class="area" @click="bindSelectArea" ref="areaEle">
                        <span v-for="(item, index) in mock_area_arr" :key="item.id" class="item-area" :class="{'active' : (areaSelIdx == index), 'hide': item.flag}" :data-id="item.id">{{item.value}}</span>
                    </div>
                    <div class="confirm">
                        <button :disabled="areaSelIdx == -1" class="btn" :class="[areaSelIdx == -1 ? 'btn01' : '']" @click="bindConfirm">
                            确认报名
                        </button>
                    </div>
                </template>
                <!-- 国考 -->
                <template v-else-if="!isRegister && mockData.subject_id == 8">
                    <div class="agency">
                        <div v-for="(item, index) in mock_area_arr" :key="item.id" class="item" @click="bindSelectAgency(index)">
                            <img class="lt" :src="checkBoxPath(index)" alt="">
                            <div class="rt">{{item.value}}</div>
                        </div>
                        <button :disabled="agencySelIdx == -1" class="confirm01" :class="[agencySelIdx != -1 ? 'active01' : '']" @click="bindConfirm">确认报名</button>
                    </div>
                </template>
                <!-- 报名成功 -->
                <template v-else-if="isRegister">
                    <mockDrainage :mockData="mockData" :username="jump_wx_username" :image="jump_wx_imagePath" type="pop"></mockDrainage>
                </template>
            </div>
            <div class="closeBtn">
                <img src="https://file.huatu.com/static/miniprogram/shop/h5/mock/icon-mock-pop-close.png" alt="" @click="bindClosePop">
            </div>
        </div>
    </van-popup>
</template>

<script>
import { computed, reactive, toRefs, ref, watchEffect, nextTick } from 'vue';
import { isEmptyObject } from '../../utils/util';
import mockDrainage from './drainage.vue';
export default {
    name: "mock-pop",
    props: {
        show: Boolean,
        isRegister: Number,
        mockData: Object,
        areaSelIdx: Number,
        agencySelIdx: Number,
        callBy: Number
    },
    components: {
        mockDrainage,
    },
    setup(props, ctx) {
        const state = reactive({
            jump_wx_username: 'gh_0c444ff8ecb7',
            jump_wx_imagePath: 'https://file.huatu.com/static/miniprogram/shop/h5/mock/bg-jump-htztk.png',
            mock_area_arr: [],
            isRegister: 0
        });
        const areaEle = ref(null);
        const isRegister = computed(() => {
            return props.isRegister && props.callBy;
        });
        const checkBoxPath = computed(() => {
            return (index) => {
                return `https://file.huatu.com/static/miniprogram/shop/h5/mock/icon-mock-checkbox-${props.agencySelIdx == index ? 'checked' : 'null'}.png`;
            }
        });

        watchEffect(() => {
            if(!isEmptyObject(props.mockData)) {
                let res = [],
                    _data = props.mockData.mock_area;
                for(let key in _data) {
                    res.push({
                        id: key,
                        value: _data[key]
                    })
                }
                state.mock_area_arr = res;
            }
        });

        watchEffect(() => {
            if(props.show && (!(props.isRegister && props.callBy) && props.mockData && props.mockData.subject_id != 8)) {
                nextTick(() => {
                    alignAreaItem();
                });
            }
        });

        const bindSelectArea = (e) => {
            ctx.emit('onSelectArea', e)
        }

        const bindSelectAgency = (index) => {
            ctx.emit('onSelectAgency', index)
        }
        
        const bindClosePop = () => {
            ctx.emit('onClosePo')
        }

        const bindConfirm = () => {
            ctx.emit('confirm');
        }

        // 判断当前省考地区个数并实现最后一行对齐的方法
        const alignAreaItem = () => {
            let item = Array.from(areaEle.value.childNodes).find(item => item.className === 'item-area'); // 找出地区item
            if(item) {
                let areaWidth = areaEle.value.offsetWidth - 22, // 22 为.area的padding-left-right之和
                    itemWidth =  item.offsetWidth + 18, // 18 为.item-area的margin-left-right之和
                    per = parseInt(areaWidth / itemWidth); // 每行能容下的最多个数
                let alls = state.mock_area_arr.length, // 地区数据的总个数
                    spare = per - (alls % per); // 最后一行空余的个数
                if(alls % per == 0) return; // 如果最后一行占满，则不需要补齐
                while(spare) {
                    state.mock_area_arr.push({
                        flag: true, // 区分正常地区数据还是补位数据，补位数据flag == true
                        id: parseInt(new Date().getTime() * Math.random()),
                        value: spare
                    });
                    spare --;
                }
            }
        }

        return {
            ...toRefs(state),
            isRegister,
            // mock_area_arr,
            areaEle,
            bindSelectArea,
            bindSelectAgency,
            bindClosePop,
            checkBoxPath,
            bindConfirm
        }
    }
}
</script>

<style scoped lang="scss">
    .mock-pop{
        .pop-content{
            width: 82.7%;
            margin: 0 auto;
            .header{
                position: relative;
                width: 101.7%;
                height: 118px;
                background-image: url(https://file.huatu.com/static/miniprogram/shop/h5/mock/mock-bg-pop-register-success.png);
                background-repeat: no-repeat;
                background-size: cover;
                background-position: 0 0;
                color: #FFFFFF;
                img {
                    width: 16px;
                    height: 16px;
                    position: absolute;
                    left: 12px;
                    top: 38px;
                }
                .title{
                    position: absolute;
                    height: 19px;
                    top: 43px;
                    left: 20px;
                    &.success{
                        width: 128px;
                        background: url(https://file.huatu.com/static/miniprogram/shop/h5/mock/txt-pop-success@2x.png) no-repeat 0 0;
                        background-size: contain;
                    }
                    &.agency{
                        width: 96px;
                        background: url(https://file.huatu.com/static/miniprogram/shop/h5/mock/txt-pop-agency@2x.png) no-repeat 0 0;
                        background-size: contain;
                    }
                    &.area{
                        width: 64px;
                        background: url(https://file.huatu.com/static/miniprogram/shop/h5/mock/txt-pop-area@2x.png) no-repeat 0 0;
                        background-size: contain;
                    }
                }
                .subTitle{
                    font-size: 11px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    position: absolute;
                    top: 65px;
                    left: 20px;
                }

            }
            .notRegister{
                width: 100%;
                height: 90px;
                background-image: url(https://file.huatu.com/static/miniprogram/shop/h5/mock/mock-bg-pop-register-book@2x.png);
                .noRegs{
                    left: 14px;
                    top: 14px;
                }
                .title{
                    left: 20px;
                    top: 20px;
                }
                .subTitle{
                    left: 20px;
                    top: 40px;
                }
            }
            .body{
                position: relative;
                width: 100%;
                // margin-top: -9px;
                // background: #fff;
                border-radius: 8px;
                box-sizing: border-box;
                .area{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    padding: 20px 11px;
                    justify-content: space-between;
                    width: 100%;
                    overflow: hidden;
                    overflow-y: auto;
                    max-height: 278px;
                    padding-bottom: 64px;
                    .item-area{
                        width: 54px;
                        height: 24px;
                        background: rgba(0, 0, 0, 0.04);
                        border-radius: 14px;
                        text-align: center;
                        line-height: 24px;
                        margin: 0 9px;
                        margin-bottom: 18px;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                    }
                    .active{
                        color: #FC6C21;
                        background: #FFEFE6;
                    }
                    .hide{
                        opacity: 0;
                    }
                }
                .confirm{
                    border-radius: 8px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 64px;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #fff;
                    box-shadow: 0px -2px 4px 0px rgba(198, 198, 198, 0.2);
                    .btn{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #fff;
                        width: 72%;
                        height: 40px;
                        background: linear-gradient(90deg, #FF9D5B 0%, #FC6C21 100%);
                        border-radius: 20px;
                        font-size: 14px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        opacity: 1;
                    }
                    .btn01{
                        opacity: 0.34;
                    }
                }
                .agency {
                    display: flex;
                    flex-direction: column;
                    padding: 20px;
                    flex-grow: 1;
                    .item{
                        display: flex;
                        margin-bottom: 18px;
                        .lt{
                            margin-top: 3px;
                            margin-right: 4px;
                            width: 14px;
                            height: 14px;
                        }
                        .rt{
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #666666;
                        }
                    }
                    .confirm01 {
                        width: 72%;
                        height: 40px;
                        background: linear-gradient(90deg, #FF9D5B 0%, #FC6C21 100%);
                        opacity: 0.34;
                        border-radius: 20px;
                        margin: 12px auto 16px auto;
                        line-height: 40px;
                        text-align: center;
                        color: #fff;
                        font-size: 14px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #FFFFFF;
                    }
                    .active01{
                        opacity: 1;
                    }
                }
            }
            .closeBtn{
                padding-top: 12px;
                display: flex;
                justify-content: center;
                img{
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }
</style>