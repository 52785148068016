<template>
    <div class="detail-comment">
        <van-list v-if="state.listData.length > 0" v-model:loading="state.loading" loading-text="加载中..." :finished="state.finished"  :immediate-check="false" finished-text="没有更多了" @load="onLoad" :offset="10">
            <van-cell v-for="(item ,index) in state.listData" :key="index">
                <div class="item">
                    <div class="lt">
                        <img :src="item.avatar" class="img-head">
                    </div>
                    <div class="rt">
                        <div class="tp">
                            <div class="name">{{item.nickname}}</div>
                            <div class="stars">
                                <i class="icon-stars-on" v-for="(itemChild ,indexChild) in item.score/2" :key="indexChild"></i>
                                <i class="icon-stars" v-for="(itemChild ,indexChild) in (10-item.score)/2" :key="indexChild"></i>
                            </div>
                        </div>
                        <div class="content">{{item.content}}</div>
                        <div class="time">{{item.create_time}}</div>
                    </div>
                </div>
            </van-cell>
        </van-list>
        <vantEmpty :show="state.empty_show" type="detail" text="暂无评论～"></vantEmpty>
    </div>
</template>

<script>
import { reactive, getCurrentInstance, onMounted, watchEffect} from "vue";
import vantEmpty from "../../components/vantEmpty/index.vue";
export default {
	name: "detail-comment",
    components: {
        vantEmpty
    },
    props: {
        courseData: Object
    },
	setup(props,context) {
        console.log(props,context)
        const { proxy } = getCurrentInstance();
        const state = reactive({
            pinglunData: [],//评论接口数据
            listData:[],//评论列表
            starsData: [1, 2, 3, 4, 5], 
            courseData:[],//课程信息
            page: 1,//页码
            empty_show: false,//空数据
            total: 0,//数据总数
            loading: false,//列表加载
            finished: false,//分页加载完成
        });

        watchEffect(() => {
            console.log("数据监听===>", props.courseData);
            if(props.courseData !== ''){
                state.courseData = props.courseData;
            }
        })

        onMounted(() => {
            getData(1);//获取数据
        })

        // 上拉加载
        const onLoad = () => {
            state.page++;
            state.loading = true;

            getData();//获取数据
        }

        // 获取资料
	    const getData = (page) => {
            proxy.$toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: "加载中..."
            });
            if(page == 1){
                state.pinglunData = [];
                state.page = page;
            }
            proxy.$http.get("/api/course/comment",{
                    params: {
                        cid: (state.courseData.platform=='3' ||state.courseData.platform=='6' ) ? state.courseData.courseid:state.courseData.course_id,//区分神2课程ID,
                        platform : state.courseData.platform,
                        page: state.page
                    }
                })
                .then(res => {
                    const body = res.data.data;
                    proxy.$toast.clear();

                    if(res.data.code == '200'){
                        state.pinglunData = body;
                        state.loading = false;
                        state.total = body.total;

                        if(state.listData == '' && body.list == ''){
                            state.empty_show = true;
                        } else {
                            state.empty_show = false;
                        }

                        if (body.list == null || body.list.length === 0) {
                            // 加载结束
                            state.finished = true;
                            return;
                        }

                        // 将新数据与老数据进行合并
                        state.listData = state.listData.concat(body.list);

                        //如果列表数据条数>=总条数，不再触发滚动加载
                        if (state.listData.length >= state.total) {
                            state.finished = true;
                        }

                    } else {
                        proxy.$toast(res.data.message);
                    }
                })
                .catch(error => console.log(error))
        }

		return {
            state,
            getData,
            onLoad
		}
	}
};
</script>

<style scoped lang="scss" src="../../views/detail/index.scss"></style>