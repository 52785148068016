import wx from 'weixin-js-sdk';

const WxShare = (option) => {
    // 微信分享成功
    // function shareSuccess() {
    //     dialog.toast('分享成功', 'success', 1500);
    // }
    // // 微信分享取消
    // function shareCancel() {
    //     dialog.toast('分享取消', 'error', 1500);
    // }
    var url = encodeURIComponent(location.href.split('#')[0])
    $.getJSON('//apixue.huatu.com/share/link?url=' + url + '&callback=?', function (res) {
        var data = res.data
        // alert(JSON.stringify(res));
        if (res.code == 1) {
            wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: data.appId, // 必填，公众号的唯一标识
                timestamp: data.timestamp, // 必填，生成签名的时间戳
                nonceStr: data.nonceStr, // 必填，生成签名的随机串
                signature: data.signature,// 必填，签名
                jsApiList: [ // 必填，需要使用的JS接口列表
                    'updateAppMessageShareData',
                    'updateTimelineShareData',
                    'onMenuShareWeibo',
                    'onMenuShareTimeline',
                    'onMenuShareAppMessage',
                    'onMenuShareQQ',
                    'onMenuShareQZone'
                ],
                openTagList: ['wx-open-launch-weapp']
            })


          
            //   alert('接口成功');
        } else if (res.code == 0) {
            //   alert('接口失败');
            // dialog.toast(res.message, 'error', 1500);
        }
    });

    // 微信分享
    //   wx.ready(function () {
        var wxTitle,wxDesc,wxLink,wxImgUrl;
        if(typeof option!='undefined' && option.shareTitle!='undefined'){
            wxTitle =(option && option.shareTitle) || $(document).attr('title') || '华图小店';
        }
        if(typeof option!='undefined' && option.shareDesc!='undefined'){
            wxDesc = (option && option.shareDesc) || $('meta[name=description]').attr('content') || '欢迎来到华图小店~';
        }
        if(typeof option!='undefined' && option.shareImg!='undefined'){
            wxImgUrl = (option && option.shareImg) || 'https://file.huatu.com/static/share/xiaodian_icon.png';
        }
        wxLink = window.location.href,

        wx.updateAppMessageShareData({
            title: wxTitle, // 分享标题
            desc: wxDesc, // 分享描述
            link: wxLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: wxImgUrl, // 分享图标
            success: function (res) {
                // console.log(res);
                // alert('分享成功:updateAppMessageShareData')
            }
        });
        wx.updateTimelineShareData({
            title: wxTitle, // 分享标题
            link: wxLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: wxImgUrl, // 分享图标
            success: function (res) {
                // alert('分享成功:updateTimelineShareData')
                // console.log(res);
            }
        });
        wx.onMenuShareWeibo({
            title: wxTitle, // 分享标题
            desc: wxDesc, // 分享描述
            link: wxLink, // 分享链接
            imgUrl: wxImgUrl, // 分享图标
            success: function () {
                // shareSuccess()
            },
            cancel: function () {
                // shareCancel()
            }
        });
        wx.onMenuShareTimeline({
            title: wxTitle, // 分享标题
            link: wxLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: wxImgUrl, // 分享图标
            success: function () {
                // 用户点击了分享后执行的回调函数
            }
        });
        wx.onMenuShareAppMessage({
            title: wxTitle, // 分享标题
            desc: wxDesc, // 分享描述
            link: wxLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: wxImgUrl, // 分享图标
            success: function () {
                // 用户点击了分享后执行的回调函数
                // alert('onMenuShareAppMessage')
            }
        });
        wx.onMenuShareQQ({
            title: wxTitle, // 分享标题
            desc: wxDesc, // 分享描述
            link: wxLink, // 分享链接
            imgUrl: wxImgUrl, // 分享图标
            success: function () {
                // 用户确认分享后执行的回调函数
            },
            cancel: function () {
                // 用户取消分享后执行的回调函数
            }
        });
        wx.onMenuShareQZone({
            title: wxTitle, // 分享标题
            desc: wxDesc, // 分享描述
            link: wxLink, // 分享链接
            imgUrl: wxImgUrl, // 分享图标
            success: function () {
                // 用户确认分享后执行的回调函数
            },
            cancel: function () {
                // 用户取消分享后执行的回调函数
            }
        });
    // })
}

function getMeta(metaName) {
    const metas = document.getElementsByTagName('meta');

    for (let i = 0; i < metas.length; i++) {
        if (metas[i].getAttribute('name') === metaName) {
            return metas[i].getAttribute('content');
        }
    }

    return '';
}

export default WxShare