<template>
  <div class="page-lesson">
    <div v-for="(item ,index) in state.data.list" :key="index">
      <template v-if="platform !== 3">
        <!-- 一级目录 -->
        <template v-if="item.ml_type == 2">
          <div class="lesson-item lesson-item-01" @click="bindAudition(item.is_trial,item.url)">
            <div class="li">
              <i class="icon-dian"></i>
              <div class="name f-owt-two">{{index+1}}. {{item.name}}</div>
              <div class="txt">{{item.teacher}} | {{item.time}}</div>
              <i class="icon-radio" v-if="item.is_trial == '1'">试听</i>
            </div>
          </div>
        </template>
        <!-- 二级目录 -->
        <template v-if="item.ml_type == 1">
          <div class="tit-box" @click.stop="bindLessonChange01(item.id,index)">
            <p class="tit f-owt-two">{{item.name}}</p>
            <i class="icon-radio" v-if="item.is_trial === 1">试听</i>
            <i class="top-green" :class="{'top-green-01': item.show}"></i>
          </div>
          <template v-if="item.child">
            <div class="lesson-item" v-show="item.show">
              <div class="li" v-for="(item01 ,index01) in item.child.list" :key="index01" @click="bindAudition(item01.is_trial,item01.url)">
                <i class="icon-dian"></i>
                <div class="name f-owt-two">{{index01+1}}. {{item01.name}}</div>
                <div class="txt">{{item01.teacher}} | {{item01.time}}</div>
                <i class="icon-radio" v-if="item01.is_trial == '1'">试听</i>
              </div>
              <div class="more" @click="getMore(1)" v-if="item.child.next > 0">加载更多<i class="icon-more"></i></div>
            </div>
          </template>
        </template>
        <!-- 三级目录 -->
        <template v-if="item.ml_type == 0">
          <div class="tit-box" @click.stop="bindLessonChange01(item.id,index)">
            <p class="tit f-owt-two">{{item.name}}</p>
            <i class="icon-radio" v-if="item.is_trial === 1">试听</i>
            <i class="top-green" :class="{'top-green-01': item.show}"></i>
          </div>
          <template v-if="item.child">
            <div v-for="(item01 ,index01) in item.child.list" :key="index01" v-show="item.show">
              <div class="tit-box" @click.stop="bindLessonChange02(item01.id,index,index01)">
                <p class="tit tit-01 f-owt-two">{{item01.name}}</p>
                <i class="icon-radio" v-if="item01.is_trial === 1">试听</i>
                <i class="top-green" :class="{'top-green-01': item01.show}"></i>
              </div>
              <template v-if="item01.child">
                <div class="lesson-item" v-show="item01.show">
                  <div class="li" v-for="(item02 ,index02) in item01.child.list" :key="index02" @click="bindAudition(item02.is_trial,item02.url)">
                    <i class="icon-dian"></i>
                    <div class="name f-owt-two">{{index02+1}}. {{item02.name}}</div>
                    <div class="txt">{{item02.teacher}} | {{item02.time}}</div>
                    <i class="icon-radio" v-if="item02.is_trial == '1'">试听</i>
                  </div>
                  <div class="more" @click="getMore(2)" v-if="item01.child.next > 0">加载更多<i class="icon-more"></i></div>
                </div>
              </template>
            </div>
          </template>
        </template>
      </template>
      <!-- 神2二级目录 -->
      <template v-if="platform == 3 || platform == 4 || platform == 5 || platform == 6">
        <template v-if="state.lessonListType == 'withChapter'">
          <p class="tit">{{item.chapterName}}</p>
          <div class="lesson-item" v-for="(item01 ,index01) in item.lessons" :key="index01" @click="bindAudition(item01.is_trial,item01.url)">
            <div class="li">
              <i class="icon-dian"></i>
              <div class="name">{{item01.name}}</div>
              <div class="txt">{{item01.teacher}} | {{item01.time}}</div>
              <i class="icon-radio" v-if="item01.is_trial == '1'">试听</i>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="lesson-item" @click="bindAudition(item.is_trial,item.url)">
            <div class="li">
              <i class="icon-dian"></i>
              <div class="name">{{item.name}}</div>
              <div class="txt">{{item.teacher}} | {{item.time}}</div>
              <i class="icon-radio" v-if="item.is_trial == '1'">试听</i>
            </div>
          </div>
        </template>
      </template>
    </div>
    <div class="more" @click.stop="getMore(0)" v-if="state.data.next > 0">加载更多<i class="icon-more"></i></div>
    <vantEmpty :show="state.empty_show" type="detail" text="暂无课程～"></vantEmpty>
  </div>
</template>
<script>
import { reactive, getCurrentInstance, watchEffect, onMounted } from 'vue'
export default {
  name: 'page-lesson',
  props: {
    courseData: Object,
    platform: '',
    goodsid: '',
    course_id: String,
    load: Boolean,
    source: String
  },
  setup(props, context) {
    const { proxy } = getCurrentInstance()
    const state = reactive({
      data: [], //课程目录数据
      platform: '', //课程归属
      goodsid: '', //商品ID
      course_id: '', //课程ID
      empty_show: false, //空数据
      lessonListType: '', //神2列表类型
      mlTpye: 0, //当前级别
      parent_id: 0, //父级ID
      index: 0, //一级index
      index01: 0, //二级index
      moreData: [1, 1, 1] //123级分页
    })

    watchEffect(() => {
      if (props.goodsid !== undefined) {
        state.goodsid = props.goodsid
      }
      if (props.course_id !== undefined) {
        state.course_id = props.course_id
      }
    })

    onMounted(() => {
      // oao课程初始化课程目录
      if (props.load) {
        getLesson(1) //获取课程目录
      }
    })

    // 获取课程目录
    const getLesson = (page) => {
      proxy.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '加载中...'
      })

      if (page == 1) {
        //初始化
        state.data = []
        state.lessonListType = '' //神2列表类型
        state.mlTpye = 0
        state.parent_id = 0
        state.index = 0
        state.index01 = 0
        state.moreData = [1, 1, 1]
      }

      proxy.$http
        .get('/api/course/lesson', {
          params: {
            parent_id:
              props.platform == '4' || props.platform == '5'
                ? state.goodsid
                : state.parent_id,
            page: state.moreData[state.mlTpye],
            cid:
              props.platform == '3' || props.platform == '6'
                ? state.goodsid
                : state.course_id, //区分神2课程ID,
            platform: props.platform
          }
        })
        .then((res) => {
          // console.log("课程目录===>", res.data)
          const body = res.data.data
          proxy.$toast.clear()

          if (res.data.code == '200') {
            const index = state.index
            const index01 = state.index01

            if (body.list == '') {
              state.empty_show = true
            } else {
              state.empty_show = false
            }

            for (let i in body.list) {
              body.list[i].show = false
            }

            if (state.mlTpye == 1) {
              //一级目录获取子集

              if (state.moreData[state.mlTpye] > 1) {
                let list = state.data.list[index].child.list.concat(body.list)
                state.data.list[index].child = body
                state.data.list[index].child.list = list
              } else {
                state.data.list[index].child = body
              }
            } else if (state.mlTpye == 2) {
              //二级目录获取子集

              if (state.moreData[state.mlTpye] > 1) {
                let list = state.data.list[index].child.list[
                  index01
                ].child.list.concat(body.list)
                state.data.list[index].child.list[index01].child = body
                state.data.list[index].child.list[index01].child.list = list
              } else {
                state.data.list[index].child.list[index01].child = body
              }
            } else {
              //一级目录
              if (body.lessonListType) {
                state.lessonListType = body.lessonListType
              }

              if (state.moreData[state.mlTpye] > 1) {
                let list = state.data.list.concat(body.list)
                state.data = body
                state.data.list = list
              } else {
                state.data = body
              }
            }
          } else {
            proxy.$toast(body.message)
          }
        })
        .catch((error) => console.log(error))
    }

    // 课程目录一级
    const bindLessonChange01 = (id, index) => {
      if (!state.data.list[index].show) {
        state.mlTpye = 1
        state.parent_id = id
        state.index = index
        state.data.list[index].show = true
        state.moreData[state.mlTpye] = 1
        getLesson()
      } else {
        state.data.list[index].show = false
      }
    }

    // 课程目录二级
    const bindLessonChange02 = (id, index, index01) => {
      if (!state.data.list[index].child.list[index01].show) {
        state.mlTpye = 2
        state.parent_id = id
        state.index = index
        state.index01 = index01
        state.data.list[index].child.list[index01].show = true
        state.moreData[state.mlTpye] = 1
        getLesson()
      } else {
        state.data.list[index].child.list[index01].show = false
      }
    }

    // 加载更多
    const getMore = (index) => {
      state.moreData[index]++
      getLesson()
    }

    // 试听
    const bindAudition = (is_trial, url) => {
      console.log('试听', is_trial, url)
      if (props.source.indexOf('xcx') !== -1) {
        proxy.$toast('购买后请在对应的软件中听课哦～')
      } else if (is_trial == '1' && url) {
        window.location.href = url
      }
    }

    return {
      state,
      getLesson,
      getMore,
      bindLessonChange01,
      bindLessonChange02,
      bindAudition
    }
  }
}
</script>

<style scoped lang="scss" src="../../views/detail/index.scss"></style>