<!-- 确认信息来弹窗 -->
<template>
    <div class="confirm-pop" v-if="show">
        <section
            class="container"
            :class="receiveSuccess ? 'receive-success' : 'receive-normal'"
        >
            <div class="header">
                <div class="blank"></div>
                <div class="content">
                    {{
                        receiveSuccess
                            ? "恭喜您领取成功，您可通过以下方式学习课程："
                            : "同学您好，为了让老师更加了解您，请先填写个人基础信息哦。"
                    }}
                </div>
            </div>
            <main class="form">
                <component :is="getComponent()" type="pop"></component>
            </main>
            <div class="close" @click="closePop"></div>
        </section>
    </div>
</template>

<script>
import receiveConfirm from "./receiveConfirm.vue";
import popDrainage from "./popDrainage.vue";
import {
    ref,
    getCurrentInstance,
    watch,
    watchEffect,
    computed,
} from "@vue/runtime-core";
import { useStore } from "vuex";
import { isEmptyObject, getStore } from "../../utils/util";
export default {
    name: "confirm-pop",
    props: {
        show: Boolean,
        course: Object,
    },
    components: {
        receiveConfirm,
        popDrainage,
    },
    setup(props, { emit }) {
        const { proxy } = getCurrentInstance();
        let flag_hasRequest = false;
        const manuallShowSuccess = ref(false);
        const userInfo = getStore("userInfo");
        const store = useStore();
        const closePop = () => {
            emit("onClosePop", flag_hasRequest);
            if (flag_hasRequest) {
                getIfRecieve();
            }
        };
        const receiveSuccess = computed(() => {
            return (props.course.hadReceive && props.course.is_receive) || manuallShowSuccess.value == true;
        })
        const receiveData = {
            mobile: "",
            shop_id: "",
            course_id: "",
        };

        // 获取是否已经领取了课程
        const getIfRecieve = () => {
            if (
                !(
                    userInfo &&
                    userInfo !== "" &&
                    userInfo.expires_in * 1000 - new Date() > 0
                )
            ) {
                // 判断是否token过期
                return;
            }
            proxy.$http
                .get("/api/course/has_preview_course")
                .then((res) => {
                    if (res.data.code == 200) {
                        let rId = -1;
                        if (isEmptyObject(res.data.data)) {
                            // 未领取
                            console.log("未领取");
                        } else {
                            // 已领取
                            console.log("已领取");
                            rId = res.data.data.dis_id;
                        }
                        store.commit("globalData/addReceiveId", rId); // 将领取到的课程id传入store
                    } else {
                        console.log("fail:", res.data.message);
                    }
                })
                .catch((err) => {
                    console.log("err:", err);
                });
        };

        watchEffect(() => {
            if (!isEmptyObject(props.course.value)) {
                receiveData.course_id = props.course.id;
            }
        });

        watch(() => store.state.globalData.receiveConfirm, (n, o) => {
            if(!o.cTime || n.cTime - o.cTime >= 1000) {
                let data = JSON.parse(JSON.stringify(n));
                delete data.cTime;
                receiveCourse(data);
            }
        })

        const receiveCourse = (data) => {
            let userInfo = getStore("userInfo"),
                shop_id = getStore("shop_id");
            receiveData.mobile = userInfo.mobile;
            receiveData.shop_id = shop_id;
            receiveData.course_id = props.course.id;

            proxy.$toast.loading("加载中...");
            proxy.$http
                .post(
                    "/api/order/send_preview_course",
                    Object.assign(receiveData, data)
                )
                .then((res) => {
                    proxy.$toast.clear();
                    if (res.data.code == 200) {
                        flag_hasRequest = true;
                        manuallShowSuccess.value = true;
                    } else if(res.data.code == 300202) {
                        // 已经领取过了
                        flag_hasRequest = true;
                        manuallShowSuccess.value = true;
                    } else {
                        proxy.$toast(res.data.mesage);
                    }
                })
                .catch((err) => {
                    proxy.$toast.clear();
                    console.log("err:", err);
                });
        };

        const getComponent = () => {
            if (receiveSuccess.value == false) {
                return "receiveConfirm";
            } else {
                return "popDrainage";
            }
        };
        return {
            receiveSuccess,
            closePop,
            getComponent,
        };
    },
};
</script>

<style lang="scss" scoped>
.confirm-pop {
    background: rgba($color: #000000, $alpha: 0.5);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    .container {
        width: 82.6%;
        max-width: 310px;
        min-height: 100px;
        background: transparent;
        position: relative;
        .header {
            width: 101.68%;
            max-width: 315px;
            height: 126px;
            position: relative;
            .blank {
                position: absolute;
                top: 41px;
                left: 14px;
            }
            .content {
                position: absolute;
                width: 100%;
                top: 68px;
                left: 0px;
                padding: 0 20px;
                font-size: 11px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
            }
        }
        &.receive-normal .header {
            background: url(https://file.huatu.com/static/miniprogram/shop/h5/pop/bg-pop-header-pen@2x.png)
                no-repeat 0 0;
            background-size: cover;
            .blank {
                width: 102px;
                height: 24px;
                background: url(https://file.huatu.com/static/miniprogram/shop/h5/pop/icon-pop-blank-receive@2x.png)
                    no-repeat 0 0;
                background-size: cover;
            }
        }
        &.receive-success .header {
            background: url(https://file.huatu.com/static/miniprogram/shop/h5/pop/bg-pop-header-tick@2x.png)
                no-repeat 0 0;
            background-size: cover;
            height: 117px;
            .blank {
                width: 72px;
                height: 24px;
                background: url(https://file.huatu.com/static/miniprogram/shop/h5/pop/icon-pop-blank-receive-success@2x.png)
                    no-repeat 0 0;
                background-size: cover;
            }
            .content {
                bottom: 36px;
            }
        }
        .form {
            border-radius: 8px;
            background: #fff;
            margin-top: -14px;
            z-index: 1;
            position: relative;
        }
        .close {
            position: absolute;
            bottom: -45px;
            left: 50%;
            transform: translateX(-50%);
            background: url(https://file.huatu.com/static/miniprogram/shop/h5/pop/icon-pop-close@2x.png)
                no-repeat 0 0;
            background-size: cover;
            width: 32px;
            height: 32px;
        }
    }
}
</style>