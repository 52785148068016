import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name:'home',
    component: () => import('@/views/home/index.vue')
  },
  {
    path: '/search/:shop_id?',
    name: 'search',
    component: () => import('@/views/home/search.vue')
  },
  {
    path: '/index/:shop_id?/:source?',
    name: 'index',
    component: () => import('@/views/home/index.vue')
  },
  {
    path: '/mine',
    name: 'mine',
    component: () => import('@/views/mine/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/detail/:dis_id/:gu_id?/:source?',
    name: 'detail',
    component: () => import('@/views/detail/index.vue')
  },
  {
    path: '/detail/faceLesson',
    name: 'faceLesson',
    component: () => import('@/views/detail/faceLesson.vue')
  },
  {
    path: '/mock/:mock_id/:source?',
    name: 'mock',
    component: () => import('@/views/mock/index.vue')
  },
  {
		path: '/address',
		name: 'address',
		component: () => import('@/views/address/index.vue')
	},
	{
		path: '/address/add',
		name: 'addressAdd',
		component: () => import('@/views/address/add.vue')
	},
  {
    path: '/groupDetail',
    name: 'groupDetail',
    component: () => import('@/views/group/detail.vue')
  },
  {
    path: '/group',
    name: 'group',
    component: () => import('@/views/group/index.vue')
  },
  // 订单列表
  {
    path: '/order',
    name: 'order',
    component: () => import('@/views/order/index.vue')
  },
  // 订单详情
  {
    path: '/order/detail/:id/:source?',
    name: 'orderDetail',
    component: () => import('@/views/order/detail.vue')
  },
  // 申请退款
  {
    path: '/order/refund',
    name: 'orderRefund',
    component: () => import('@/views/order/refund.vue')
  },
  // 申请退款状态
  {
    path: '/order/refundStatus',
    name: 'orderRefundStatus',
    component: () => import('@/views/order/refundStatus.vue')
  },
  // 确认订单
  {
    path: '/confirm',
    name: 'orderConfirm',
    component: () => import('@/views/order/confirm.vue')
  },
  // 支付成功
  {
    path: '/success',
    name: 'success',
    component: () => import('@/views/pay/success.vue')
  },
  // 订单详情
  {
    path: '/order/logistics/:id',
    name: 'orderLogistics',
    component: () => import('@/views/order/logistics.vue')
  },
  // 领取课程页面
  {
    path: '/receive',
    name: 'receiveCourse',
    component: () => import('@/views/receive/index.vue')
  },
  {
      path: '/protocol',
      name: 'protocol',
      component: () => import('@/views/protocol/index.vue')
  }
]

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
