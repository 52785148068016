<!-- 推广按钮逻辑 -->
<template>
    <div ref="spread" class="detail-spread" v-show="source == 'xcx'">
        <img
            src="https://file.huatu.com/static/miniprogram/shop/h5/detail/icon-spread-shop-h5@2x.png"
            alt=""
        />
    </div>
</template>

<script>
import wx from 'weixin-js-sdk';
import { onMounted, onUnmounted, ref } from "vue";
export default {
    name: "detail-spread",
    props: {
        source: String,
    },
    setup() {
        const spread = ref(null);
        let originX = 0;
        let originY = 0;
        let isMouseMove = false;
        let offsetLeft = 0;
        let offsetTop = 0;
        let width = 0;
        let height = 0;
        let screenWidth = document.getElementsByTagName('html')[0].offsetWidth;
        let screenHeight = document.getElementsByTagName('html')[0].offsetHeight;

        onMounted(() => {
            const spreadDom = spread.value;
            if (!spreadDom) return;
            spreadDom.addEventListener("touchstart", handleDrapStart);
            spreadDom.addEventListener("touchmove", handleDrapMove);
            spreadDom.addEventListener("touchend", handleDrapEnd);
        });

        onUnmounted(() => {
            const spreadDom = spread.value;
            if (!spreadDom) return;
            spreadDom.removeEventListener("touchstart", handleDrapStart);
            spreadDom.removeEventListener("touchmove", handleDrapMove);
            spreadDom.removeEventListener("touchend", handleDrapEnd);
        });

        const handleDrapMove = (e) => {
            isMouseMove = true;
            const ev = e || event;
            const spreadDom = spread.value;
            const moveX = ev.clientX || ev.touches[0].clientX;
            const moveY = ev.clientY || ev.touches[0].clientY;

            

            let moveToX = moveX - originX + offsetLeft;
            let moveToY = moveY - originY + offsetTop;
            if(moveToX < 0) {
                moveToX = 0;
            } else if(moveToX > screenWidth - width) {
                moveToX = screenWidth - width;
            }

            if(moveToY < 0) {
                moveToY = 0;
            } else if(moveToY > screenHeight - height){
                moveToY = screenHeight - height;
            }
            // spreadDom.style.left = moveToX + "px";
            spreadDom.style.top = moveToY + "px";
        };

        const handleDrapStart = (e) => {
            const ev = e || event;
            const spreadDom = spread.value;

            offsetLeft = spreadDom.offsetLeft;
            offsetTop = spreadDom.offsetTop;
            width = spreadDom.offsetWidth;
            height = spreadDom.offsetHeight;
            originX = ev.clientX || ev.touches[0].clientX;
            originY = ev.clientY || ev.touches[0].clientY;
        };
        const handleDrapEnd = (e) => {
            if (isMouseMove) {
                isMouseMove = false;
                return;
            } else {
                if (wx && wx.miniProgram) {
                    wx.miniProgram.navigateBack();
                    wx.miniProgram.postMessage({
                        data: { from: "detail", option: "share" },
                    });
                }
            }
        };

        return {
            spread
        };
    },
};
</script>

<style scoped lang="scss" src="../../views/detail/index.scss"></style>