<template>
    <div class="page-face" v-if="courseData.type == 2 || courseData.type == 3 || courseData.type == 4">
        <!-- 课程信息 -->
        <section class="detail-info">
            <div class="price-box">
                <div class="lt">
                    <div class="price"><span>¥</span>{{state.goodsDetail.price}}</div>
                </div>
            </div>
            <div class="title">{{state.goodsDetail.name}}</div>
            <div class="time">课程安排：{{state.goodsDetail.duration_day}}天{{state.goodsDetail.duration_night}}晚 · {{state.goodsDetail.class_hour}}课时</div>
        </section>
        <!-- 选择课程 -->
        <div class="detail-face">
            <!-- 面授课选择 -->
            <section class="detail-features">
                <div class="item" @click="bindAreaPop" v-if="state.courseArea.name">
                    <div class="lt">
                        <div class="tit">上课地点</div>
                        <div class="txt">{{state.courseArea.name}}</div>
                    </div>
                    <div class="rt">
                        <i class="icon-right"></i>
                    </div>
                </div>
                <div class="item" @click="bindClassPop" v-if="state.classType.name">
                    <div class="lt">
                        <div class="tit">协议类型</div>
                        <div class="txt f-owt">{{state.classType.name}}</div>
                    </div>
                    <div class="rt">
                        <i class="icon-right"></i>
                    </div>
                </div>
                <div class="item" @click="bindTimePop" v-if="state.startTime.name">
                    <div class="lt">
                        <div class="tit">开课时间</div>
                        <div class="txt">{{state.startTime.name}}</div>
                    </div>
                    <div class="rt">
                        <i class="icon-right"></i>
                    </div>
                </div>
                <div class="item" @click="bindStayPop" v-if="state.isStay.name">
                    <div class="lt">
                        <div class="tit">住　　宿</div>
                        <div class="txt">{{state.isStay.name}}</div>
                    </div>
                    <div class="rt">
                        <i class="icon-right"></i>
                    </div>
                </div>
            </section>
            <!-- 上课地点 -->
            <van-popup :show="state.areaShow" position="bottom" :style="{ height: '50%' }">
                <van-picker title="选择上课地点" :columns="state.areaData" @confirm="bindAreaConfirm" :columns-field-names="customFieldName" @cancel="onCancel"/>
            </van-popup>
            <!-- 协议类型 -->
            <van-popup :show="state.calssShow" position="bottom" :style="{ height: '50%' }">
                <van-picker title="选择班型" :columns="state.classTypeData" @confirm="bindClassConfirm" :columns-field-names="customFieldClass" @cancel="onCancel"/>
            </van-popup>
            <!-- 开课时间 -->
            <van-popup :show="state.timeShow" position="bottom" :style="{ height: '50%' }">
                <van-picker title="选择开课时间" :columns="state.startTimeData" @confirm="bindTimeConfirm" :columns-field-names="customFieldTime" @cancel="onCancel"/>
            </van-popup>
            <!-- 住宿 -->
            <van-popup :show="state.stayShow" position="bottom" :style="{ height: '50%' }">
                <van-picker title="选择是否住宿" :columns="state.stayData" @confirm="bindStayConfirm" :columns-field-names="customFieldStay" @cancel="onCancel"/>
            </van-popup>
        </div>
        <!-- 课程tab -->
        <div class="detail-tabs">
            <van-tabs :active="state.active" color="#FC6C21" line-width="14px" title-active-color="#FC6C21" title-inactive-color="#999999" swipeable @click="bindclick">
                <van-tab>
                    <template #title><button>简介</button></template>
                    <div class="ms-intro">
                        <div v-html="courseData.intro"></div>
                        <!-- <img :src="courseData.intro"> -->
                    </div>
                </van-tab>
                <van-tab>
                    <template #title><button>课程表</button></template>
                    <div class="lesson">
                        <div class="tit-box" v-for="(item ,index) in state.lessonData" :key="index" @click="bindLesson(item.id,item.goods_id)">
                            <p class="tit f-owt-two">{{item.name}}</p>
                            <i class="icon-right"></i>
                        </div>
                        <vantEmpty :show="state.lessonData == ''" type="detail" text="暂无课表～"></vantEmpty>
                    </div>
                </van-tab>
                <van-tab>
                    <template #title><button>师资介绍</button></template>
                    <section class="detail-teacher">
                        <div class="item" v-for="(item, index) in state.teacherData" :key="index">
                            <div class="lt">
                                <img :src="item.avatar" class="lt-img">
                            </div>
                            <div class="rt">
                                <div class="tp">
                                    <div class="name">{{item.name}}</div>
                                    <div class="short-intor">{{item.shortIntro}}</div>
                                </div>
                                <div class="star">
                                    <i class="icon-star" v-for="(item01, index01) in item.star" :key="index01"></i>
                                    <span>{{item.score}}分</span>
                                </div>
                                <div class="intor-txt">{{item.intro}}</div>
                            </div>
                        </div>
                         <vantEmpty :show="state.teacher_empty_show" type="detail" text="正在上传中～"></vantEmpty>
                    </section>
                </van-tab>
            </van-tabs>
        </div>
    </div>
</template>

<script>
import { getCurrentInstance, onMounted, watchEffect, reactive} from "vue";
import { useRouter, useRoute } from "vue-router";
export default {
	name: "page-face",
    props: {
        courseData: Object,
    },
	setup(props,context) {
        const { proxy } = getCurrentInstance();
        const $route = useRoute();
	    const $router = useRouter();
        const state = reactive({
            courseData:[],//课程信息
            areaData: [], // 上课地点信息
			classTypeData: [], // 班型信息
			startTimeData: [], // 开课时间信息
			stayData: [], // 住宿信息
			goodsDetail: {}, // 商品详情
			courseArea: {}, // 上课地点
            active: 0,//tabs选中
            lessonData:[],//课程表
            courseArea:{ //地区
				id: '',
				name: '',
			},
			classType: { // 班型
				id: '',
				name: '',
			},
			startTime: { // 开课时间
				id: '',
				name: '',
			},
			isStay: { //住宿
				id: '',
				name: '',
			},
			areaShow: false,
			calssShow: false,
			timeShow: false,
			stayShow: false,
			currentCourseData: { // 当前课程基本信息
				id: '', // 课程 ID
				areaid: '', // 所属地区 ID
				goodsid: '', // 商品 ID
				offline_courseId: '', // 线下课 ID
				price: '', // 价格
			},
        });

        watchEffect(() => {
            if(props.courseData){
                state.courseData == props.courseData;
            }
        })

        onMounted(() => {
            
        })

        // 面授弹窗
        const bindAreaPop = () => {
            state.areaShow = !state.areaShow;
        }
        // 上课地点确认弹窗
        const bindAreaConfirm = (value) => {
            console.log(value.id, value.name);

            state.courseArea.id = value.id;
            state.courseArea.name = value.name;

            getClassType(props.courseData.courseid,props.courseData.platform,state.courseArea.id);

            state.areaShow = false;//关闭弹窗
        };

        // 班型弹窗
        const bindClassPop = () => {
            state.calssShow = !state.calssShow;
        }
        // 班型确认弹窗
        const bindClassConfirm = (value) => {
            console.log(value);

            state.classType.id = value.proto_id_goodsid;
            state.classType.name = value.show_name;

            getStartTime(state.classTypeData, state.classType.id);

            state.calssShow = false;//关闭弹窗
        };

        // 开课时间弹窗
        const bindTimePop = () => {
            state.timeShow = !state.timeShow;
        }
        // 开课时间确认弹窗
        const bindTimeConfirm = (value) => {
            console.log(value);

            state.startTime.id = value.label;
            state.startTime.name = value.label;

            getGoodsDetail(state.startTimeData, state.startTime.name);

            state.timeShow = false;//关闭弹窗
        };

        // 住宿弹窗
        const bindStayPop = () => {
            state.stayShow = !state.stayShow;
        }
        // 住宿确认弹窗
        const bindStayConfirm = (value) => {
            console.log(value);

            state.isStay.id = value.is_stay;
            state.isStay.name = value.stay_name;
            state.goodsDetail = value;
            state.stayShow = false;//关闭弹窗
            getBuyStatus(state.goodsDetail.id);
        };

        // 弹窗数据指向
        const customFieldName = {
            text: 'name',
            id: 'id'
        };

        // 弹窗班型数据指向
        const customFieldClass = {
            text: 'show_name',
            id: 'proto_id_goodsid'
        };

        // 弹窗开课时间数据指向
        const customFieldTime = {
            text: 'label',
            id: 'label'
        };

        // 弹窗住宿数据指向
        const customFieldStay = {
            text: 'stay_name',
            id: 'is_stay'
        };

        // 关闭所有弹窗
        const onCancel = () => {
            state.areaShow = false;//关闭弹窗
            state.calssShow = false;//关闭弹窗
            state.timeShow = false;//关闭弹窗
            state.stayShow = false;//关闭弹窗
        }

        // 获取上课区域
        const getClassLocation = (cid,platform,initial) => {
            proxy.$http.get("/api/course/areas/" + cid + '/' + platform)
                .then(res => {
                    console.log("地区数据===>", res.data)
                    const body = res.data.data;

                    if(res.data.code == '200'){
                        
                        resetAreaData(body.area_data);

                        if (!initial) { // 上课地点
                            state.courseArea.id = body.default_data.id;
                            state.courseArea.name = body.default_data.name;
                        }
                        
                        getClassType(cid,platform, body.default_data.id, initial);

                        state.currentCourseData.id = state.courseData.id;
                        state.currentCourseData.areaid = state.courseArea.id;
                        state.currentCourseData.offline_courseId = body.offline_courseId;
                    } else {
                        proxy.$toast(res.data.message);
                    }
                })
                .catch(error => console.log(error))
        }

        // 重组上课地点数据
        const resetAreaData = (data) => {
            data.map(function(item, index) {
                if (item.is_show === 1) {
                    item.value = item.id;
                    item.label = item.name;

                    state.areaData.push(item);
                }

                if (item.son_area && item.son_area.length > 0) {
                    resetAreaData(item.son_area);
                }
            });
            console.log("areaData===>",state.areaData)
        }

        // 获取班型
        const getClassType = (cid,platform,area_id, initial) => {
            // state.classTypeData = [];

            proxy.$http.get("/api/course/protocols/"+ cid +"/" + area_id + '/' + platform)
                .then(res => {
                    console.log("协议商品数据===>", res.data)
                    const body = res.data.data;

                    if(res.data.code == '200'){
                        let fromData = [];

                        body.map(function(item) {
                            var classTypeItem = item;

                            classTypeItem.value = item.proto_id_goodsid;
                            classTypeItem.label = item.show_name;

                            
                            if(classTypeItem.goods_data_list != ''){
                                fromData.push(classTypeItem);
                            }
                        });
                        state.classTypeData = fromData;
                        console.log("classTypeData===>",state.classTypeData)

                        if (!initial) { // 默认班型存起来
                            state.classType = {
                                id: body[0].proto_id_goodsid,
                                name: body[0].show_name,
                            };
                        }
                        getStartTime(body, state.classType.id, initial);
                    } else {
                        proxy.$toast(res.data.message);
                    }
                })
                .catch(error => console.log(error))
        }

        // 获取开课时间
        const getStartTime = (classTypeData, classTypeId, initial) => {
            state.currentCourseData.goodsid = classTypeId;
            state.startTimeData = [];

            classTypeData.map(function(item, index) {
                if (item.proto_id_goodsid == classTypeId) {
                    var startTimeData = [];

                    item.goods_data_list.map(function(timeItem) {
                        var startTimeItem = timeItem;

                        startTimeItem.value = timeItem.date;
                        startTimeItem.label = timeItem.date;

                        startTimeData.push(startTimeItem);
                    });

                    state.startTimeData = startTimeData;
                    if (!initial) { // 默认时间存起来
                        state.startTime = {
                            id: startTimeData[0].label,
                            name: startTimeData[0].label,
                        };
                    }
                    console.log("startTimeData===>",state.startTimeData)
                    getGoodsDetail(startTimeData, state.startTime.name, initial);
                }
            });
        }

        // 获取课程详细信息
        const getGoodsDetail = (goodsData, startTimeId, initial) => {
            goodsData.map(function(item) {
                if (item.date == startTimeId) {
                    for(let i in item.list){
                        item.list[i].stay_name = item.list[i].is_stay === 0 ? '不住宿':'住宿';
                    }
                    state.stayData = item.list;
                    console.log("stayData===>",state.stayData)

                    if (!initial) {
                        state.isStay = {
                            id: item.list[0].is_stay,
                            name: item.list[0].stay_name,
                        };
                        state.goodsDetail = item.list[0];
                        state.currentCourseData.goodsid = item.list[0].id;
                        state.currentCourseData.price = item.list[0].price;
                        getBuyStatus(state.currentCourseData.goodsid);
                    };
                    console.log(state.isStay);
                }
            });
        }

        // 获取购买
        const getBuyStatus = (goodsid) => {
            proxy.$http.get("/api/course/get_id/"+ props.courseData.courseid +"/" + goodsid + '/' + props.courseData.platform)
                .then(res => {
                    const body = res.data.data;

                    if(res.data.code == '200'){
                        context.emit('getBuyStatus',body.id);
                    } else {
                        proxy.$toast(res.data.message);
                    }
                })
                .catch(error => console.log(error))
        }
 
        // 切换tabs标签前触发
        const bindclick = (index) => {
            state.active = index;
            if(index === 1 && state.lessonData.length <= 0){
                getLesson(props.courseData.courseid, props.courseData.platform);
            }
            return true;
        }

        // 获取面授课教师
        const getTeacher = (cid,platform) => {
            proxy.$http.get("/api/course/teacher/" + cid + '/' + platform)
                .then(res => {
                    console.log("面授课教师数据===>", res.data)
                    const body = res.data.data;

                    if(res.data.code == '200'){
                        state.teacherData = body;

                        if(state.teacherData == ''){
                            state.teacher_empty_show = true;
                        }
                    } else {
                        proxy.$toast(res.data.message);
                    }
                })
                .catch(error => console.log(error))
        }

        // 获取课程目录
        const getLesson = (cid,platform) => {
            proxy.$http.get("/api/course/goods/" + cid + '/' + platform)
                .then(res => {
                    console.log("课程目录===>", res.data)
                    const body = res.data.data;

                    if(res.data.code == '200'){
                        state.lessonData = body;
                    } else {
                        proxy.$toast(res.data.message);
                    }
                })
                .catch(error => console.log(error))
        }

        // 跳转课程表
        const bindLesson = (id,goodsid) => {
            $router.push({
                path: '/detail/faceLesson',
                query: {
                    id: id,
                    goodsid: goodsid,
                    platform: props.courseData.platform
                }
            });
        }

        

       

		return {
            state,
            customFieldName,
            customFieldClass,
            customFieldTime,
            customFieldStay,
            bindAreaPop,
            bindAreaConfirm,
            bindClassPop,
            bindClassConfirm,
            bindTimePop,
            bindTimeConfirm,
            bindStayPop,
            bindStayConfirm,
            getClassLocation,
            bindclick,
            getTeacher,
            getLesson,
            bindLesson,
            onCancel
		}
	}
};
</script>

<style scoped lang="scss">
button{
    margin: 0px;
    padding: 0px;
    border: 0px;
    outline: none;
    background: transparent;
}
.detail-info{
    padding: 15px 15px 12px 15px;
    background: #ffffff;
    margin-bottom: 10px;
    .price-box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 14px;
        .lt{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .yj-price{
                color: #999999;
                line-height: 20px;
                text-decoration: line-through;
                padding-left: 8px;
            }
        }
        .rt{
            .num{
                background: #FCF0E9;
                line-height: 20px;
                font-size: 11px;
                color: #FC6C21;
                padding: 0 5px;
            }
        }
    }
    .title{
        text-align: start;
        padding-bottom: 5px;
    }
    .time{
        font-size: 13px;
        line-height: 18px;
    }
}
.detail-face{
    background: #ffffff;
    padding: 0 15px;
    margin-bottom: 10px;
    .item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #F1F1F1;
        padding: 10px 0;
        .lt{
            font-size: 13px;
            color: #999999;
            line-height: 18px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .txt{
                color: #333333;
                padding-left: 15px;
                width: 250px;
            }
        }
        .rt{
            .icon-right{
                display: block;
                width: 14px;
                height: 14px;
                background: url(../../assets/icons/icon-right.png) center/100%,100% no-repeat;
            }
        }
    }
    .item:last-child{
        border: none;
    }
}
.detail-tabs{
    .intro{
        padding: 15px;
        background: #ffffff;
    }
    .ms-intro{
        img{
            width: 100%;
        }
    }
    .detail-teacher{
        background: #ffffff;
        padding: 0 15px;
        .item{
            display: flex;
            justify-content: flex-start;
            padding: 15px 0;
            .lt{
                .lt-img{
                   width: 54px;
                   height: 54px; 
                   border-radius: 50%;
                }
                margin-right: 16px;
            }
            .rt{
                display: flex;
                flex-direction: column;
                .tp{
                    display: flex;
                    align-items: center;
                    margin-top: 6px;
                    .name{
                        font-size: 16px;
                        font-weight: 500;
                        color: #333333;
                        line-height: 22px;
                        padding-right: 9px;
                    }
                    .short-intor{
                        padding: 2px 5px;
                        background: #FFF9F5;
                        border-radius: 2px;
                        font-size: 12px;
                        font-weight: 500;
                        color: #FC6C21;
                        line-height: 20px;
                    }
                }
                .star{
                    display: flex;
                    align-items: center;
                    .icon-star{
                        display: block;
                        width: 10px;
                        height: 10px;
                        background: url(../../assets/icons/icon-stars-on.png) center/100% 100% no-repeat;
                        margin-left: 2px;
                    }
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    color: #999999;
                    line-height: 17px;
                    margin-top: 10px;
                    span{
                        padding-left: 4px;
                    }
                }
                .intor-txt{
                    font-size: 14px;
                    color: #999999;
                    line-height: 22px;
                    margin-top: 10px;
                }
            }
        }
    }
}
.lesson{
    padding: 15px;
    background: #FFFFFF;
    box-shadow: 0px 0px 9px 0px rgba(96, 105, 142, 0.06);
    border-radius: 4px;
    .tit-box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .icon-right{
            width: 16px;
            height: 16px;
            background: url(../../assets/icons/icon-right.png) center/100% 100% no-repeat;
        }
        .tit{
            font-size: 15px;
            line-height: 21px;
            font-weight: 500;
            padding: 10px 15px;
        }
        .tit-01{
            font-weight: 400;
        }
    }
}
</style>