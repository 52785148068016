<template>
    <div class="van-tit">
        <span class="icon-back" @click="bindBack" v-if="!is_back"></span>
        <div class="title">{{title}}</div>
    </div>
</template>

<script>
import { onMounted, reactive} from "vue";
import { useRouter, useRoute } from "vue-router";
export default {
	name: "van-tit",
    props: {
        title: String,
        url: String,
        go: String,
        is_back: Boolean,
    },
	setup(props) {
        const $router = useRouter();
        onMounted(() => {
            
        })

        //返回页面
        const bindBack = () => {
            if(props.url){
                $router.replace({
                    path: props.url
                });
            } else if(props.go){
                $router.go(props.go)
            } else {
                $router.go(-1)
            }
        }

        return {
            bindBack
        }

	}
};
</script>

<style scoped lang="scss">
.van-tit{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EDEDED;
    position: relative;
    .icon-back{
        display: block;
        width: 18px;
        height: 18px;
        background: url(../../assets/icons/icon-back.png) center/100% 100% no-repeat;
        position: absolute;
        left: 10px;
        top: 16px;
    }
}
</style>