<template>
  <section class="detail-tabs special-detail" :class="{ 'hideBgAndPlace': state.hideBgAndPlace }">
    <detailFeatures :courseData="state.courseData" :groupData="state.groupData" :coursesData="coursesData" @bindHjConfirm="bindHjConfirm" @bindShowBuyNotice="bindShowBuyNotice" @bindPtShow="bindPtShow" :source="state.source" :type="featureType"></detailFeatures>

    <van-tabs v-model:active="active" :class="[is_mock ? 'mock' : '']" color="#FE5C32" title-active-color="#333333" title-inactive-color="#999999" line-width="14px" @click="bindclick" sticky animated>
      <template v-if="!is_mock">
        <van-tab>
          <template #title><button>课程详情</button></template>
          <!-- 购买须知 拼团规则 合集课程选择 -->
          <template v-if="courseData.platform != 8" style="display:block;float:left;width:100%;overflow:hidden;">
            <div v-html="courseData.intro" class="intro" v-show="!state.empty_show"></div>
            <div class="teacher" v-if="courseData.teachers">
              <div class="tit" v-if="courseData.teachers.length > 0">
                <i class="icon"></i>
                <span>讲师介绍</span>
              </div>
              <div class="item" v-for="(item, index) in courseData.teachers" :key="index">
                <div class="lt">
                  <img :src="item.avatar" class="img-head">
                </div>
                <div class="rt">
                  <div class="tp">
                    <div class="name">{{ item.truename }}</div>
                    <div class="subject">{{ item.subject_group_names }}</div>
                  </div>
                  <div class="summary">{{ item.summary }}</div>
                </div>
              </div>
            </div>
            <vantEmpty :show="state.empty_show" type="detail" text="暂无课程详情～"></vantEmpty>
          </template>
          <!-- 新神2课程课程详情 -->
          <template v-if="courseData.platform == 8" style="display:block;float:left;width:100%;overflow:hidden;">
            <Introduction :xsCourseInfo="xsCourseInfo" :source="source" ref="courseInfo"></Introduction>
          </template>
        </van-tab>
        <template v-if="courseData.type !== 5">
          <van-tab>
            <template #title><button>课程大纲</button></template>
            <zxqCode :zxq_qrcode="state.qrcodeData" :browser="state.browser" v-if="state.qrcodeData !== '' && state.qrcodeData.url !== ''"></zxqCode>
            <XsLesson v-if="courseData.platform == 8" :courseItem="courseData" :platform="courseData.platform" :goodsid="courseData.goodsid" :course_id="courseData.course_id" :source="source" ref="lessonList"></XsLesson>
            <lessonList v-if="courseData.platform != 8" :platform="courseData.platform" :goodsid="courseData.goodsid" :course_id="courseData.course_id" :source="source" ref="lessonList"></lessonList>
          </van-tab>
          <!-- platform 等于8 代表来自新神2 暂时不展示 资料和评论模块 -->
          <van-tab v-if="courseData.platform != 8">
            <template #title><button>资料</button></template>
            <ZiLiao :platform="courseData.platform" :goodsid="courseData.goodsid" :course_id="courseData.course_id" ref="ref_ziLiao"></ZiLiao>
          </van-tab>
          <van-tab v-if="courseData.platform != 8">
            <template #title><button>评论</button></template>
            <pingLun :courseData="courseData" ref="pingLun"></pingLun>
          </van-tab>
        </template>
      </template>
      <template v-else>
        <van-tab>
          <template #title><button>课程详情</button></template>
          <div v-html="courseData.intro" class="intro" v-show="!state.empty_show"></div>
          <div class="teacher" v-if="courseData.teachers">
            <div class="tit" v-if="courseData.teachers.length > 0">
              <i class="icon"></i>
              <span>讲师介绍</span>
            </div>
            <div class="item" v-for="(item, index) in courseData.teachers" :key="index">
              <div class="lt">
                <img :src="item.avatar" class="img-head">
              </div>
              <div class="rt">
                <div class="tp">
                  <div class="name">{{ item.truename }}</div>
                  <div class="subject">{{ item.subject_group_names }}</div>
                </div>
                <div class="summary">{{ item.summary }}</div>
              </div>
            </div>
          </div>
          <vantEmpty :show="state.empty_show" type="detail" text="暂无课程详情～"></vantEmpty>
        </van-tab>
      </template>
    </van-tabs>
  </section>
  <!-- 购买须知 -->
  <buyNotice :show="state.buyShow" @bindBuyPop="bindBuyPop" :isBuy="state.isBuy" :source="source" :courseData="courseData"></buyNotice>
  <!-- 拼团规则 -->
  <van-popup :show="state.ptShow" position="bottom" style="min-height:320px;" round @click-overlay="bindPtPop">
    <div class="pt-box">
      <p class="tit">尊敬的奋斗者，拼团规则如下：</p>
      <p class="txt">1. 活动时间：{{ groupData.group_start_date }}-{{ groupData.group_end_date }}；</p>
      <p class="txt">2. 成团人数{{ groupData.limit_number }}人；</p>
      <p class="txt" v-if="groupData.while_time !== 0 || groupData.while_H !== 0 || groupData.while_I !== 0">3.
        开团后您需要在<span v-if="groupData.while_time !== 0">{{ groupData.while_time }}天</span><span v-if="groupData.while_H !== 0">{{ groupData.while_H }}小时</span><span v-if="groupData.while_I !== 0">{{
                        groupData.while_I }}分</span>内完成拼团，不然就会拼团失败；</p>
      <p class="txt" v-if="groupData.while_time == 0 && groupData.while_H == 0 && groupData.while_I == 0">3.
        拼团过程中<span class="bold">不支持退款</span>，若在成团有效期内未成团，系统会原路退款；</p>
      <p class="txt" v-else>4. 拼团过程中<span class="bold">不支持退货</span>，若在成团有效期内未成团，系统会原路退款；</p>
      <div class="btn" @click="bindPtPop">我知道了</div>
    </div>
  </van-popup>
</template>
<script>
import {
  getCurrentInstance,
  onMounted,
  watchEffect,
  reactive,
  ref,
  watch,
  computed,
  onUpdated
} from 'vue'
import lessonList from '../../components/detail/lesson.vue'
import ZiLiao from '../../components/detail/ziliao.vue'
import pingLun from '../../components/detail/pinglun.vue'
import vantEmpty from '../../components/vantEmpty/index.vue'
import zxqCode from '../../components/zxqCode/index.vue'
import detailFeatures from '../../components/detail/features.vue'
import buyNotice from '../../components/detail/buynotice.vue'
export default {
  name: 'detail-tabs',
  components: {
    lessonList,
    ZiLiao,
    pingLun,
    vantEmpty,
    zxqCode,
    detailFeatures,
    buyNotice
  },
  props: {
    courseData: Object,
    groupData: Object,
    source: String,
    browser: String,
    is_buy: Boolean
  },
  setup(props, context) {
    const { proxy } = getCurrentInstance()
    const lessonList = ref()
    const courseInfo = ref()
    const ref_ziLiao = ref()
    const pingLun = ref()
    const active = ref(0) //tabs选中
    const featureType = ref('intro')
    const state = reactive({
      empty_show: false, //课程详情
      teacher_empty_show: false, //教师
      teacherData: [], //面授师资介绍
      courseData: [],
      qrcodeData: '',
      browser: '',
      hideBgAndPlace: false,
      buyShow: false,
      ptShow: false,
      isBuy: false, // 是否购买课程
      openAppImgTarget: '' //
    })
    const xsCourseInfo = ref({})
    const is_mock = computed(() => {
      if (props.courseData == '') {
        return false
      } else {
        return 'mock_range' in props.courseData
      }
    })
    const getXsInfo = () => {
      let params = {
        goodsNum: props.courseData.code,
        source: 'xd'
      }
      proxy.$http
        .get(process.env.VUE_APP_XS_URL + '/api/course/goods/goods_info', {
          params
        })
        .then((res) => {
          if (res.data.code == 10000) {
            xsCourseInfo.value = res.data.data
            console.log('===:::', xsCourseInfo)
          } else {
            proxy.$toast(res.data.message)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
    watchEffect(() => {
      if (props.courseData !== '') {
        state.courseData = props.courseData
      }
      // 课程详情为空展示404页面
      if (props.courseData.intro == '' && props.courseData.teachers !== '') {
        state.empty_show = true
      } else {
        state.empty_show = false
      }
      // 新神2课程详情用自己组件 区分共用
      if (props.courseData.platform == 8) {
        getXsInfo()
      }
    })

    watchEffect(() => {
      if (state.qrcodeData !== '' && state.qrcodeData.url !== '') {
        state.hideBgAndPlace = true
      }
    })
    console.log('n9999fdf9sdfas', props.is_buy)
    state.isBuy = props.is_buy
    watch(
      () => {
        return props.is_buy
      },
      (n, o) => {
        console.log('n9999fdf9sdfas', n)
        if (n == true) {
          getGuideQrCode()
        }
      },
      { deep: true }
    )

    onMounted(() => {})

    // 切换tabs标签前触发
    const bindclick = (index) => {
      console.log('index==', index)
      if (index === 1) {
        if (props.is_buy == true) {
          getGuideQrCode()
        }
        lessonList.value && lessonList.value.getLesson(1)
      } else if (index === 2) {
        ref_ziLiao.value && ref_ziLiao.value.getData(1)
      } else if (index === 3) {
        pingLun.value && pingLun.value.getData(1)
      }

      return true
    }

    // 获取课程引流码
    const getGuideQrCode = () => {
      // if(!(state.courseData.platform == 2 || state.courseData.platform == 3)) {
      //     return;
      // }
      let url = `/api/order/guide_qrcode/${state.courseData.courseid}/${state.courseData.platform}`
      // let url = '/api/order/guide_qrcode/5010620/3';
      proxy.$http
        .get(url)
        .then((res) => {
          let body = res.data.data
          console.log('body:', body)
          if (res.data.code == 200) {
            if (body.url !== '' && body.url !== undefined) {
              state.qrcodeData = body
            } else {
              state.qrcodeData = ''
            }
            isWeiXin() //是否为微信浏览器
          } else {
            proxy.$toast(res.data.message)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }

    //判断是否是微信浏览器的函数
    const isWeiXin = () => {
      //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
      var ua = window.navigator.userAgent.toLowerCase()
      //通过正则表达式匹配ua中是否含有MicroMessenger字符串
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        state.browser = '1'
      } else {
        state.browser = '2'
      }
    }

    // tabs组件中监听features组件购买须知事件
    const bindShowBuyNotice = () => {
      state.buyShow = !state.buyShow
    }

    // 购买须知事件
    const bindBuyPop = () => {
      state.buyShow = !state.buyShow
    }

    // 拼团须知事件
    const bindPtPop = () => {
      state.ptShow = !state.ptShow
    }

    // tabs组件中监听features组件拼团须知事件
    const bindPtShow = () => {
      state.ptShow = !state.ptShow
    }
    console.log('process.env.NODE_ENV==>', process.env.NODE_ENV)
    let openInstallPm = null
    const initOpenInstall = (params) => {
      if (openInstallPm) return openInstallPm
      openInstallPm = new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = '//web.cdn.openinstall.io/openinstall.js'
        document.body.appendChild(script)
        script.onload = function () {
          new window.OpenInstall(
            {
              /*appKey必选参数，openinstall平台为每个应用分配的ID*/
              // appKey : "dxdy1n",//线上
              // appKey:"cwfznu",//测试
              appKey:
                process.env.NODE_ENV == 'production' ? 'dxdy1n' : 'cwfznu',
              /*自定义遮罩的html*/
              channelCode: params.channelCode,
              onready: function () {
                resolve(this)
              }
            },
            params
          )
        }
      })
      return openInstallPm
    }
    let openInstallHandler
    initOpenInstall({
      channelCode: process.env.NODE_ENV == 'production' ? '7001*15' : '7002*11'
    }).then((handler) => {
      openInstallHandler = handler
    })
    //点击下载/打开华图在线app方法
    const handleNavApp = () => {
      console.log('handleNavApp=>', {
        type: 4,
        rid: state.courseData.course_id, //在线的课程id
        isLive: state.courseData.is_online_live //是否为直播课程
      })
      if (!openInstallHandler) return
      openInstallHandler.wakeupOrInstall({
        channelCode:
          process.env.NODE_ENV == 'production' ? '7001*15' : '7002*11',
        data: {
          type: 4,
          rid: state.courseData.course_id, //在线的课程id
          isLive: state.courseData.is_online_live //是否为直播课程
        }
      })
      return
    }

    onUpdated(() => {
      //获取课程详情里的下载app图片 增加onclick事件
      let imgList = Array.from(
        document.getElementsByClassName('downloadAppBtn')
      )
      console.log('有downloadAppBtn，长度是=>', imgList.length)
      if (imgList.length > 0) {
        state.openAppImgTarget = imgList[imgList.length - 1] //取最后一个元素
      }
      //只有在线课程（蓝色后台同步过来的）详情里有这个图片 所以此处无需判断课程来源 只需要判断是否为小程序打开
      //小程序打开课程详情 不需要打开app
      //后续如有新需求 需注意！！！
      if (state.openAppImgTarget && props.source.indexOf('xcx') == -1) {
        state.openAppImgTarget.onclick = () => {
          handleNavApp()
        }
      }
    })
    return {
      state,
      active,
      bindclick,
      lessonList,
      courseInfo,
      ref_ziLiao,
      pingLun,
      is_mock,
      featureType,
      bindShowBuyNotice,
      bindBuyPop,
      bindPtPop,
      bindPtShow,
      xsCourseInfo
    }
  }
}
</script>
<style>
.special-detail .intro img {
  max-width: 100% !important;
  height: auto !important;
  vertical-align: top !important;
}
</style>
<style scoped lang="scss">
@import '../../views/detail/index.scss';

button {
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: none;
  background: transparent;
}

.hideBgAndPlace {
  /deep/ .place {
    height: 10px;
  }

  /deep/ .bg {
    display: none;
  }
}

.detail-tabs {
  .mock {
    /deep/ .van-tab {
      justify-content: flex-start !important;
      padding-left: 33px !important;
    }

    /deep/ .van-tabs__line {
      transform: translateX(40px) !important;
    }
  }
}

/deep/ .van-tabs__content {
  background: transparent;
}

.pt-box {
  background: url(https://file.huatu.com//static/miniprogram/shop/h5/detail/bg-groupbuy-notice@2x.png)
    center/100% 100% no-repeat;
  box-sizing: border-box;
  padding: 20px 20px 0 20px;
  position: relative;
  overflow: hidden;

  .tit {
    color: #666666;
    line-height: 20px;
    padding-bottom: 20px;
  }

  .txt {
    color: #333333;
    line-height: 20px;
    padding-bottom: 15px;
  }

  /deep/.van-count-down {
    display: inline-block;
  }

  .btn {
    // width: 335px;
    line-height: 40px;
    background: linear-gradient(90deg, #ff9d5b 0%, #fe5c32 100%);
    border-radius: 20px;
    font-size: 15px;
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    margin: 0 auto;
    margin-top: 5px;
  }

  .color {
    color: #fe5c32;
  }

  .bold {
    color: #000000;
    font-weight: bold;
  }
}
</style>