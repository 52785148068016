<template>
  <!-- 网课课程信息 -->
  <section class="detail-info">
    <!-- 普通课程价格拼团课程价格 -->
    <div class="price-box" :class="[is_spike ? 'spike' : '', spikeStatus == 2 ? 'end' : (spikeStatus == 1 ? 'processing' : '')]">
      <template v-if="is_mock">
        <div class="mock-title">{{courseData.title}}</div>
      </template>
      <template v-else-if="is_spike">
        <div class="lt" v-if="courseData.group_status !== 1">
          <div class="price"><span>¥</span>{{spikeStatus == 2 ? courseData.origin_price : courseData.price }}</div>
        </div>
        <div class="lt origin-price" v-if="spikeStatus != 2">
          <div class="origin-price"><span>¥</span>{{courseData.origin_price}}</div>
        </div>
        <div class="countdown">
          <div class="tit">
            <div class="icon"></div>
          </div>
          <countDown :time="countTime" @bindFinished="bindFinished"></countDown>
        </div>
      </template>
      <template v-else>
        <div class="lt" v-if="courseData.group_status !== 1">
          <div class="price"><span>¥</span>{{courseData.price}}</div>
        </div>
        <div class="lt" v-if="courseData.group_status == 1">
          <div class="price"><span>¥</span>{{groupData.group_price}}</div>
          <div class="group-capsule">
            <img src="https://file.huatu.com/static/miniprogram/shop/h5/detail/icon-detail-groupbuy-capsule@2x.png" alt="">
            <div class="num">{{groupData.limit_number}}人团</div>
          </div>
        </div>
        <div class="rt" v-if="false/*courseData.group_status == 1*/">
          <div class="num">{{groupData.limit_number}}人拼团价</div>
        </div>
      </template>
    </div>
    <div v-if="!is_mock" class="title">{{courseData.title}}</div>
    <div v-else class="mock-time">{{exam_time}}</div>
    <div class="time" v-if="courseData.live_stage_time && courseData.live_stage_time != ''">直播时间：{{courseData.live_stage_time}}</div>
    <div class="bt-icon">
      <div class="item">
        <template v-if="!is_mock">
          <template v-if="courseData.limit_num > 0">
            <!-- <i class="icon-gwc"></i>
            <span>{{courseData.buy_num}}/{{courseData.limit_num}} 限购</span> -->
          </template>
          <template v-else>
            <i class="icon-pf"></i>
            <span>9.0分</span>
          </template>
        </template>
        <div v-else class="mock-item-name">报考科目: {{courseData.item_name}}</div>
      </div>
      <div class="item">
        <template v-if="!is_mock">
          <!-- <i class="icon-pf"></i>
          <span>9.0分</span> -->
        </template>
        <div v-else class="mock-subject-name">科目: {{courseData.subject_name}}</div>
      </div>
      <div class="item" v-if='is_mock || (!is_mock && !(courseData.platform=="2" && courseData.lesson_num=="0"))'><!--处理在线课程0课时 不展示该模块-->
        <template v-if="!is_mock">
          <i class="icon-ks"></i>
          <!--非在线课程展示XXX课节-->
          <span v-if='courseData.platform!="2"'>{{courseData.lesson_num}}课节</span>
          <!--在线课程展示XXX课时-->
          <span v-if='courseData.platform=="2" && courseData.lesson_num!="0"'>{{courseData.lesson_num}}课时</span>
        </template>
        <div v-else class="mock-range" @click.stop="onShowAreaPopover">适用地区: {{courseData.mock_range}}</div>
        <div v-if="showAreaPopover" class="areapop">
          <div v-html="areas"></div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import {
  getCurrentInstance,
  watchEffect,
  computed,
  reactive,
  toRefs,
  onUnmounted
} from 'vue'
import countDown from './countdown.vue'
export default {
  name: 'detail-info',
  props: {
    courseData: Object,
    groupData: Object,
    goodsDetail: Object,
    isShowAreaPopover: Boolean
  },
  components: {
    countDown
  },
  setup(props, context) {
    const { proxy } = getCurrentInstance()
    let spikeCountingTimer = null
    const state = reactive({
      showAreaPopover: false,
      areas: '',
      countTime: 0,
      spikeStatus: 0 // 秒杀状态（未开始0，进行中1，已结束2）
    })

    // 生命周期钩子函数
    onUnmounted(() => {
      // 消除timer定时器
      if (spikeCountingTimer) {
        clearTimeout(spikeCountingTimer)
        spikeCountingTimer = null
      }
    })

    const exam_time = computed(() => {
      if (props.courseData == '') {
        return ''
      } else {
        return props.courseData.exam_time[0]
      }
    })
    const is_mock = computed(() => {
      if (props.courseData == '') {
        return true
      } else {
        return 'mock_range' in props.courseData
      }
    })
    // 判断是否为秒杀
    const is_spike = computed(() => {
      return props.courseData.flag == 3
    })

    watchEffect(() => {
      if (props.isShowAreaPopover !== undefined) {
        state.showAreaPopover = props.isShowAreaPopover
      }
    })

    // 判断秒杀结束还是未开始（包括进行中）
    watchEffect(() => {
      let cData = props.courseData
      if (cData && cData.sale_start_time) {
        if (cData.sale_start_time > 0) {
          // 还没开始
          state.spikeStatus = 0
          state.countTime = cData.sale_start_time
        } else if (cData.sale_end_time > 0) {
          // 进行中
          state.spikeStatus = 1
          state.countTime = cData.sale_end_time
        } else {
          state.spikeStatus = 2
          state.countTime = 0
        }
      }
    })

    const onShowAreaPopover = () => {
      if (state.showAreaPopover == false) {
        let arr = props.courseData.mock_range.split(',')
        let res = []
        arr.forEach((item) => {
          res.push(`<span>${item}</span>`)
        })
        state.areas = res.join('')
      }
      context.emit('onShowAreaPopover')
    }

    const bindFinished = () => {
      if (state.countTime == 0) {
        return
      }
      if (state.spikeStatus == 0) {
        state.countTime = props.courseData.sale_end_time
      }
      if (state.spikeStatus <= 2) {
        state.spikeStatus += 1
      }
    }

    return {
      ...toRefs(state),
      exam_time,
      is_mock,
      is_spike,
      onShowAreaPopover,
      bindFinished
    }
  }
}
</script>

<style scoped lang="scss">
.detail-info {
  background: #ffffff;
  margin-bottom: 10px;
  .price-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 15px 8px;
    position: relative;
    .lt {
      box-sizing: border-box;
      padding-top: 16px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .origin-price {
        color: #999999;
        line-height: 20px;
        text-decoration: line-through;
        padding-left: 8px;
      }
      .group-capsule {
        width: 87px;
        height: 20px;
        display: flex;
        align-items: center;
        margin-left: 5px;
        margin-bottom: 5px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        .num {
          position: absolute;
          right: 7px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #fe3109;
        }
      }
    }
    .lt.origin-price {
      flex-grow: 1;
      // padding-top: 3px;
      padding-left: 5px;
    }
    .rt {
      .num {
        background: #fcf0e9;
        line-height: 20px;
        font-size: 11px;
        color: #fc6c21;
        padding: 0 5px;
      }
    }
    .mock-title {
      font-size: 17px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #333;
    }
    .countdown {
      color: #ff1717;
      .tit {
        display: flex;
        justify-content: flex-end;
        .icon {
          width: 84px;
          height: 14px;
          background: url(https://file.huatu.com/static/miniprogram/shop/h5/detail/icon-spike-countdown-notStart@2x.png)
            no-repeat 0 0;
          background-size: cover;
        }
      }
    }
  }
  .spike {
    background: linear-gradient(
      90deg,
      rgba(252, 108, 33, 0) 0%,
      rgba(252, 108, 33, 0.27) 100%
    );
    margin-bottom: 8px;
  }
  .spike.end {
    background: linear-gradient(
      90deg,
      rgba(173, 173, 173, 0) 0%,
      rgba(146, 146, 146, 0.27) 100%
    );
  }
  .spike.processing .countdown .icon {
    background: url(https://file.huatu.com/static/miniprogram/shop/h5/detail/icon-spike-countdown-processing@2x.png)
      no-repeat 0 0;
    background-size: cover;
    width: 70px;
    height: 14px;
  }
  .spike.end .countdown {
    color: #999999;
    .icon {
      background: url(https://file.huatu.com/static/miniprogram/shop/h5/detail/icon-spike-countdown-end@2x.png)
        no-repeat 0 0;
      background-size: cover;
      width: 70px;
      height: 14px;
    }
  }
  .title {
    text-align: start;
    padding: 0 15px 5px;
  }
  .mock-time {
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    padding: 0 15px 0;
  }
  .time {
    font-size: 13px;
    line-height: 18px;
    padding: 0 15px 0;
    color: #333;
  }
  .bt-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    color: #999999;
    margin-top: 15px;
    padding: 0 15px 8px;
    .item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      line-height: 18px;
      position: relative;

      i {
        display: block;
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
      .icon-gwc {
        background: url(https://file.huatu.com/static/miniprogram/shop/h5/detail/icon_gwc.png)
          center/100% 100% no-repeat;
      }
      .icon-pf {
        background: url(https://file.huatu.com/static/miniprogram/shop/h5/detail/icon_pf.png)
          center/100% 100% no-repeat;
      }
      .icon-ks {
        background: url(https://file.huatu.com/static/miniprogram/shop/h5/detail/icon_ks.png)
          center/100% 100% no-repeat;
      }
      .mock-range {
        max-width: 150px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .areapop {
        position: absolute;
        top: 26px;
        right: 0;
        // min-width: 100px;
        background: #4a4a4a;
        border-radius: 5px;
        z-index: 1000;
        box-sizing: border-box;
        padding: 5px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &::before {
          content: '';
          display: block;
          width: 0;
          height: 0;
          border-bottom: 8px solid #4a4a4a;
          border-right: 8px solid transparent;
          border-left: 8px solid transparent;
          position: absolute;
          top: -8px;
          right: 15px;
        }
        /deep/ span {
          display: inline-block;
          color: #fff;
          padding: 3px;
        }
      }
    }
  }
}
</style>