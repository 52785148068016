<template>
  <div class="introduction">
    <div v-html="xsCourseInfo.detail" class="intro" v-show="xsCourseInfo.detail != '<p><br></p>'"></div>
    <div class="teacher" v-if="xsCourseInfo.teacherInfo">
      <div class="tit" v-if="xsCourseInfo.teacherInfo.length > 0">
        <i class="icon"></i>
        <span>讲师介绍</span>
      </div>
      <template v-for="(item, index) in xsCourseInfo.teacherInfo" :key="index">
        <div class="item">
          <div class="lt">
            <img :src="item.roundPhoto" class="img-head">
          </div>
          <div class="rt">
            <div class="tp">
              <div class="name">{{ item.teacherName }}</div>
              <div class="subject">{{ item.subjectGroupList }}</div>
            </div>
            <div class="summary">{{ item.introduction }}</div>
          </div>
        </div>
      </template>
      <div class="bk_content" v-if="xsCourseInfo.goodsSource =='BK'">
        <div class="bk_text">
          <span class="bk_exam">
            <i class="icon-exam"></i>
            <span>
              适用考试
            </span>
          </span>
          <span>{{xsCourseInfo.examTypeName}}</span>
        </div>
        <div class="bk_text">
          <span class="bk_exam">
            <i class="icon-serve"></i>
            <span>
              服务次数
            </span>
          </span>
          <span>{{xsCourseInfo.serviceNumber}}</span>
        </div>
        <div class="bk_text">
          <span class="bk_exam">
            <i class="icon-time"></i>

            <span>服务有效期</span></span>
          <span>{{xsCourseInfo.goodsExpiry	}}</span>
        </div>
      </div>
    </div>
    <vantEmpty :show="xsCourseInfo.detail == '<p><br></p>'" type="detail" text="暂无简介～"></vantEmpty>
  </div>
</template>
<script>
import { getCurrentInstance, nextTick } from 'vue'
export default {
  name: 'xs-info',
  props: {
    xsCourseInfo: Object
  },
  setup(props, context) {
    return {}
  }
}
</script>

<style scoped lang="scss" src="../../views/detail/index.scss"></style>
<style>
.introduction .box .bg {
  top: 0px !important;
}
</style>
<style scoped lang="scss">
.intro {
  margin-top: 10px;
}
</style>