let proxy;
// 请求地址
const url_mock_register = '/api/mock/register';
const url_mock_detail = '/api/mock/detail/';

const libs = {
    getMockDetailData(mock_id, source) {
        proxy.$toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: "加载中..."
        });

        let url = `${url_mock_detail}${mock_id}`;
        if(source.indexOf('xcx') !== -1) {
            url += ('/' + source)
        }
        return new Promise((resolve, reject) => {
            proxy.$http.get(url).then(res => {
                proxy.$toast.clear();
                resolve(res);
            }).catch(err => {
                proxy.$toast.clear();
                reject(err);
            });
        });
    },
    mockRegister(params) {
        proxy.$toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: "加载中..."
        });
        return new Promise((resolve, reject) => {
            proxy.$http.post(url_mock_register, params)
            .then(res => {
                proxy.$toast.clear();
                resolve(res);
            })
            .catch(err => {
                proxy.$toast.clear();
                reject(err);
            })
        });
    }
}

export default {
    install(app) {
        console.log('libs install');
        Object.keys(libs).forEach(key => {
            if(libs.hasOwnProperty(key)) {
                // libs[key] = libs[key].bind(app);
            }
        });
        proxy = app.config.globalProperties;
        app.config.globalProperties.$libs = libs;
    }
}