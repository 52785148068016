<template>
    <section class="detail-group">
        <div class="title"><span>{{groupData.limit_number}}人成团，可直接参团</span></div>
        <div class="item" v-for="(item ,index) in joinData" :key="index">
            <div class="lt">
                <img :src="item.avatar" class="lt-img">
            </div>
            <div class="rt">
                <div class="time">还差<span>{{groupData.limit_number-item.join_num}}</span>人拼团成功  剩余<van-count-down :time="item.endtime" format="DD 天 HH:mm:ss "/></div>
                <div class="join-btn" @click="bindBuy(item.pid)">去参团</div>
            </div>
        </div>
    </section>
</template>
<script>
import { getCurrentInstance, onMounted, watchEffect, reactive} from "vue";
import { useRouter, useRoute } from "vue-router";
export default {
	name: "detail-join-group",
    props: {
        courseData: Object,
        groupData: Object,
        userInfo: Object,
        joinData: Object
    },
	setup(props,context) {
        const { proxy } = getCurrentInstance();
        const $router = useRouter();

        /**
         * 去参团
         */
        const bindBuy = (pid) => {
            if(!props.userInfo.name){
                toLogin();//授权
            } else {
                proxy.$toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "加载中..."
                });
                $router.push({
                    path: "/confirm",
                    query: {
                        shop_id: props.courseData.shop_id,
                        id: props.courseData.id,
                        flag: '1',
                        group_id: props.groupData.id,
                        gu_user_id: pid
                    }
                });
            }
            
        }

        /**
		 * 跳转登录页
		 */
		const toLogin = () => {
			$router.push({
				path: '/login'
			})
		}

        
		return {
            bindBuy,
            toLogin
		}
	}
};
</script>

<style scoped lang="scss">
.detail-group{
    background: #ffffff;
    padding: 9px 15px;
    margin-bottom: 10px;
    .item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 0;
        .lt{
            width: 52px;
            .lt-img{
                width: 26px;
                height: 26px;
                border-radius: 50%;
            }
        }
        .rt{
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex: 1;
            width: 285px;
            height: 30px;
            background: #F6F6F6;
            border-radius: 19px;
            padding-left: 8px;
            font-size: 12px;
            .time{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: 10px;
            }
            /deep/.van-count-down{
                font-size: 10px;
            }
            .join-btn{
                width: 60px;
                height: 30px;
                box-sizing: border-box;
                background: #FFEBDF;
                border-radius: 18px;
                border: 1px solid #FFD5BF;
                font-size: 12px;
                color: #FC6C21;
                text-align: center;
                line-height: 30px;
            }
        }
    }
}
</style>