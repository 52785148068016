import { createStore } from 'vuex'

import cart from './modules/cart'
import search from './modules/search'
import userInfo from './modules/userInfo'
import globalData from './modules/globalData'

export default createStore({
  modules: {
    cart,
    search,
    userInfo,
    globalData
  },
  strict: process.env.NODE_ENV !== 'production'
})
