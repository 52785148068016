<template>
    <section class="detail-code">
        <div class="tit">
            <p v-if="courseData.active_type == 0">课程激活码：<span v-if="!buyData.is_buy">*********</span><span v-else>{{buyData.code}}</span></p>
            <p v-if="courseData.active_type == 1">论文查重优惠码：<span v-if="!buyData.is_buy">*********</span><span v-else>{{buyData.code}}</span></p>
            <i class="icon-copy" @click="copy"></i>
        </div>
        <div class="txt">购买成功后方可查看复制哦</div>
    </section>
</template>

<script>
import { reactive, onMounted, getCurrentInstance } from "vue";
import useClipboard from 'vue-clipboard3';
export default {
	name: "detail-code",
    props: {
        buyData: Object,
        courseData: Object
    },
	setup(props) {
        const { proxy } = getCurrentInstance();
        // 复制查重码
        const { toClipboard } = useClipboard()
        const copy = async () => {
            try {
                if(props.buyData.is_buy){
                    //复制
                    await toClipboard(props.buyData.code)
                    proxy.$toast("复制成功");
                }else {
                    proxy.$toast("购买成功后方可查看复制哦～");
                }
            } catch (e) {
                //复制失败
                proxy.$toast("复制失败");
            }
        }
		return {
            copy
		}
	}
};
</script>

<style scoped lang="scss" src="../../views/detail/index.scss"></style>