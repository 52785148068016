<template>
    <div class="code-box code-box-01" :class="{'code-box-tabs': fromTabs}" id="zxq_code" v-show="zxq_qrcode !== '' && zxq_qrcode.url !== ''">
        <div class="lt">
            <div class="code-title code-title-01"><i class="icon-circle"></i>获取专属课程，参与更多福利</div>
            <div class="code-test" v-if="browser == '1' || zxq_qrcode.drainage_code_type === 'other'">长按保存二维码，加入班级群</div>
            <div class="code-test" v-if="browser == '2' && zxq_qrcode.drainage_code_type === 'zxq'">点击保存二维码，加入班级群</div>
            <div class="code-btn" @click="saveQrcode" v-if="browser == '2' && zxq_qrcode.drainage_code_type === 'zxq'"><i class="icon-down"></i>保存至相册</div>
        </div>
        <div class="rt">
            <div id="qrcode" v-show="zxq_qrcode.drainage_code_type === 'zxq'" style="width:80px; height:80px;position: fixed;top: 1000px;left: 1000px;"></div>
            <div id="qr_img" v-show="zxq_qrcode.drainage_code_type === 'zxq'"><img src='' alt=''/></div>
            <img :src="zxq_qrcode.url" class="code-img" v-if="zxq_qrcode.drainage_code_type === 'other'"/>
            <div class="code-name f-owt-two">{{zxq_qrcode.drainage_code_name}}</div>
        </div>
    </div>
    <div class="place"></div>
</template>

<script>
import { watchEffect, onMounted, reactive, computed } from 'vue';
import { useRoute } from 'vue-router';
import QRCode from "qrcodejs2"; // 引入qrcode
export default {
	name: "zxq_code",
    props: {
        zxq_qrcode: '',
        browser: ''
    },

	setup(props) {
        const state = reactive({
            zxq_qrcode: ''
        });
        // 生成二维码
        const getQrcode = (url) => {
            var qrcode = new QRCode(document.getElementById("qrcode"), {
                width: 80,
                height: 80, // 高度
            })
            qrcode.makeCode(url);
            var canvas = $("#qrcode").find('canvas').get(0);
            $("#qr_img>img").attr("src",canvas.toDataURL('image/jpg')); 
        }
        const $route = useRoute();
        const fromTabs = computed(() => {
            return /^\/detail\//.test($route.path);
        });

        // 保存二维码
        const saveQrcode = () => {
            let cvs= document.getElementById('qrcode').getElementsByTagName('canvas');
            let a = document.createElement("a")
            a.href = cvs[0].toDataURL('image/png').replace('image/png', 'image/octet-stream')
            a.download = props.zxq_qrcode.drainage_code_name + ".png"
            a.click()
        }

        watchEffect(() => {
            state.zxq_qrcode = props.zxq_qrcode;
        });

        onMounted(() => {
            if(state.zxq_qrcode.drainage_code_type === 'zxq' && state.zxq_qrcode.url !== '') {
                getQrcode(state.zxq_qrcode.url);
            }
        })

		return {
            getQrcode,
            saveQrcode,
            fromTabs
		}
	}
};
</script>

<style scoped lang="scss">
.place{
    height: 100px;
}
.code-box {
    padding: 16px;
    margin: 12px 10px 0 10px;
    border-radius: 8px;
    background: #fff;
    box-sizing: border-box;
    font-weight: 500;
}
.code-box-tabs {
    margin: 12px 0px 0 0px;
    border-radius: 0px;
}
.code-box-01{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.code-box .lt, .code-box .rt{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}
.code-box .code-btn{width: 124px;border-radius: 21px;border: 1px solid #F30406;color: #F51606;font-size: 14px;line-height: 36px;text-align: center;margin-top: 14px;display: flex;align-items: center;justify-content: center;}
.code-box .code-img{width: 90px;height: 90px;}
.code-box .icon-circle{display: block;width: 12px;height: 12px; background:url(https://file.huatu.com/static/miniprogram/shop/h5/components/code_icon_circle_orange.png) center/100% 100% no-repeat;margin-right: 4px;}
.code-box .icon-down{display: block;width: 16px;height: 16px; background:url(https://file.huatu.com/static/miniprogram/huatu/code_icon_down.png) center/100% 100% no-repeat;margin-right: 4px;}
.code-box .code-name{font-size: 13px;color: #666666;line-height: 18px;padding-top: 6px;width: 77px;text-align: center;}
.code-box .code-title{line-height: 20px;color: #262626;font-size: 14px;font-weight: 500;}
.code-box .code-title-01{display: flex;align-items: center;}
.code-box .code-test{margin-top: 7px;font-size: 12px;color: rgba(38, 38, 38, .5);line-height: 17px;}
</style>