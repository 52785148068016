<template>
  <!-- 购买须知 拼团规则 合集选择 -->
  <section class="detail-features">
    <!-- 购买须知搜有网课展示 -->
    <template v-if="type == 'intro'">
      <div class="item" @click="bindBuyPop">
        <div class="lt">
          <div class="tit">购买须知，学员必读</div>
        </div>
        <div class="rt">
          <i class="icon-right"></i>
        </div>
      </div>
      <!-- 拼团课程展示 -->
      <div class="item" v-if="courseData.group_status == 1" @click="bindPtPop">
        <div class="lt">
          <div class="tit">拼团规则：开团/参团-邀请好友-满员成团</div>
        </div>
        <div class="rt">
          <i class="icon-right"></i>
        </div>
      </div>
    </template>
    <!-- 合集课程展示 -->
    <template v-else>
      <div class="hj-box" v-if="coursesData.length > 0">
        <div class="title">商品选择</div>
        <div class="hj-list" :class="{'hj-list-01':state.hjMore}">
          <div class="hj-item" v-for="(item ,index) in coursesData" :key="item.id" :class="{'hj-item-01': state.short_title == item.short_title}" @click="bindHjConfirm(item)">
            <div class="f-owt-two">{{item.short_title}}</div>
          </div>
        </div>
        <div class="hj-more" @click="hjMore" v-if="!state.hjMore && coursesData.length > 4">展开更多<i class="icon-more"></i></div>
      </div>
    </template>
    <!-- 购买须知 -->
    <!-- <buyNotice :show="state.buyShow" @bindBuyPop="bindBuyPop" :source="source" :courseData="courseData"></buyNotice> -->
    <!-- 拼团规则 -->
    <!-- <van-popup :show="state.ptShow" position="bottom" :style="{ height: '50%' }" round @click-overlay="bindPtPop">
            <div class="pt-box">
                <p class="tit">尊敬的奋斗者，拼团规则如下：</p>
                <p class="txt">1. 活动时间：{{groupData.group_start_date}}-{{groupData.group_end_date}}；</p>
                <p class="txt">2. 成团人数{{groupData.limit_number}}人；</p>
                <p class="txt" v-if="groupData.while_time !== 0 || groupData.while_H !== 0 || groupData.while_I !== 0">3. 开团后您需要在<span v-if="groupData.while_time !== 0">{{groupData.while_time}}天</span><span v-if="groupData.while_H !== 0">{{groupData.while_H}}小时</span><span v-if="groupData.while_I !== 0">{{groupData.while_I}}分</span>内完成拼团，不然就会拼团失败；</p>
                <p class="txt" v-if="groupData.while_time == 0 && groupData.while_H == 0 && groupData.while_I == 0">3. 拼团过程中<span class="bold">不支持退款</span>，若在成团有效期内未成团，系统会原路退款；</p>
                <p class="txt" v-else>4. 拼团过程中<span class="bold">不支持退货</span>，若在成团有效期内未成团，系统会原路退款；</p>
                <div class="btn" @click="bindPtPop">我知道了</div>
            </div>
        </van-popup> -->
  </section>
</template>
<script>
import { getCurrentInstance, onMounted, watchEffect, reactive } from 'vue'
export default {
  name: 'detail-header',
  emits: ['bindHjConfirm'],
  components: {
    // buyNotice
  },
  props: {
    courseData: Object,
    coursesData: Object,
    groupData: Object,
    source: String,
    type: String
  },
  setup(props, context) {
    const { proxy } = getCurrentInstance()
    const state = reactive({
      buyShow: false, //购买须知弹窗
      ptShow: false, //拼团规则弹窗
      shareShow: false, //分享弹窗
      hjMore: false //合集加载更多
    })

    watchEffect(() => {
      // 默认合集选中标题
      if (props.courseData.short_title) {
        state.short_title = props.courseData.short_title
      }
    })

    onMounted(() => {})

    // 拼团规则弹窗
    const bindPtPop = () => {
      // state.ptShow = !state.ptShow;
      context.emit('bindPtShow')
    }
    // 购买须知
    const bindBuyPop = () => {
      // state.buyShow = !state.buyShow
      context.emit('bindShowBuyNotice')
    }

    const bindHjConfirm = (value) => {
      state.dis_id = value.id
      state.short_title = value.short_title
      context.emit('bindHjConfirm', value.id)
    }

    // 合集展开
    const hjMore = () => {
      state.hjMore = true
    }

    return {
      state,
      bindBuyPop,
      bindPtPop,
      bindHjConfirm,
      hjMore
    }
  }
}
</script>

<style scoped lang="scss" src="../../views/detail/index.scss"></style>