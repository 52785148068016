<template>
  <ul class="multi_express" v-if="multi_express && multi_express.length  > 0">
		<li class="head">
			<span class="title">同学您好，您这单分批发货，含有多个物流信息，请查看下方物流明细：</span>
		</li>
		<van-steps direction="vertical" active="-1" @click="getWuliu">
			<van-step class="itemClass" v-for="item in multi_express" :key="item.nu" :data-nu="item.nu">
				<div>
					<p v-if="filterFTime(item.ftime) !== false">发货时间：{{filterFTime(item.ftime)}}</p>
					<p>物流单号：{{item.nu}}</p>
				</div>
				<van-icon name="arrow" color="#999999"/>
			</van-step>
		</van-steps>
	</ul>
    <wuLiu :show="show" :wuliuData="wuliuData" :wuliu_empty_show="wuliu_empty_show" @bindPop="bindPop"></wuLiu>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, computed } from 'vue'; 
import wuLiu from "../wuLiu/index.vue";
export default {
    props:{
        order_id: Number,
        multi_express: Array
    },
    components: {
        wuLiu
    },
    setup(props) {
        const { proxy } = getCurrentInstance();
        const state = reactive({
            show: false,
			wuliuData: [],//物流信息
			wuliu_empty_show: false,
        });
		// 添加计算属性，对发货时间做格式化处理
        const filterFTime = computed(() => {
            return (_ftime) => {
				if(_ftime == '') {
					return false;
				}
                let fir = _ftime.split(' ');
                let nir = fir[0].replace(/\./g, '-');
                return nir + ' ' + fir[1];
            }
        });
        const getWuliu = (e) => {
            let elNode = findNu(e.target, 'nu');
            if(elNode === false) {
				return;
			}
            proxy.$http.get("/api/transport/detail",{
				params: {
					order_id: props.order_id,
                    nu: elNode.dataset.nu
				}
			})
			.then(res => {
				console.log("物流===>", res.data)
				const body = res.data;
				if(res.data.code == '200'){
					if(body.data == ''){
						state.wuliu_empty_show = true;
					} else {
						state.wuliu_empty_show = false;
					}

					for(let i in body.data){
						body.data[i].date = body.data[i].ftime.slice(0,5);
						body.data[i].time = body.data[i].ftime.slice(6,11);
					}
					state.wuliuData = body;
					state.show = true;
				} else {
					proxy.$toast(body.message);
				}
			})
			.catch(error => console.log(error))
        }
        const findNu = (elNode, key) => {
            if(!elNode) {
				return false;
			}
            if(elNode.dataset && key in elNode.dataset) {
                return elNode;
            }
            if(elNode.parentNode) {
				return findNu(elNode.parentNode, key)
			}
            return false;
        }
        const bindPop = () => {
            state.show = false;
        }
        return {
            getWuliu,
            bindPop,
            filterFTime,
            ...toRefs(state)
        }
    }   
}
</script>

<style lang="scss" scoped>
.multi_express {
	// width: 355px;
	min-height: 56px;
	margin: 0 auto;
	background: #fff;
	border-radius: 8px;
	overflow: hidden;
	overflow-y: auto;
	font-family: PingFangSC-Regular, PingFang SC;
    margin: 12px 16px 0 16px;
    // padding: 0 10px;
	.head {
		// width: 355px;
		height: 56px;
		background-image: url(https://file.huatu.com/static/miniprogram/shop/h5/order/icon_order_multi_express.png);
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		box-sizing: border-box;
		padding: 10px;
		.title {
			font-size: 13px;
			font-weight: 400;
			color: #FC6C21;
			line-height: 18px;
			text-align: start;
			// margin: 10px;
		}
	}
    /deep/ .van-step:last-child{
        margin-bottom: 15px;
        padding-bottom: 0 !important;
        .van-step__line {
            width: 1px !important;
            height: 70% !important
        }
    }
	.itemClass {
		padding: 5px 10px 10px 0 !important;
		&:after{
			border: none !important;
		}
		/deep/ .van-step__title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			p{
				height: 18px;
				font-size: 13px;
				font-weight: 400;
				color: #4A4A4A;
				line-height: 18px;
				margin-top: 8px;
			}
		}
		/deep/ .van-step__circle{
			width: 6px;
			height: 6px;
			background: #FC6C21;
		}
		/deep/ .van-step__line{
			background-color: transparent !important;
			border: none !important;
			border-left: 1px dashed #E5E7E8 !important;
		}
	}
}
</style>