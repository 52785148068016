<template>
  <div class="page-lesson">
    <template v-if="state.levelNum == 3">
      <div v-for="(item ,index) in state.data" :key="index">
        <!-- 有三级 大纲 - 模块 - 课节 目录 -->
        <template v-if="item.level == 1">
          <div class="tit-box tit-box_title1" @click.stop="bindLessonChange01(item,index)">
            <p class="tit_icon">
              <i class="icon"></i>
              <span class="tit f-owt-two">{{item.title}}</span>
            </p>
            <i class="icon-radio" v-if="item.isTryListen === 1">试听</i>
            <i class="top-green" :class="{'top-green-01': item.show}"></i>
          </div>
          <template v-if="item.child">
            <div v-for="(item01 ,index01) in item.child" :key="index01" v-show="item.show">
              <div class="tit-box tit-box_title2" @click.stop="bindLessonChange02(item01,index,index01)">
                <p class="tit tit-01 f-owt-two">
                  {{item01.title}}
                </p>
                <i class="icon-radio" v-if="item01.isTryListen === 1">试听</i>
                <i class="top-green" :class="{'top-green-01': item01.show}"></i>
              </div>
              <template v-if="item01.child">
                <div class="lesson-item" v-show="item01.show">
                  <div class="li" v-for="(item02 ,index02) in item01.child" :key="index02">
                    <i class="icon-dian"></i>
                    <div class="name f-owt-two"> <span class="lesson_name">{{index02+1}}</span>
                      <div class="lesson_title">{{item02.title}}</div>
                    </div>
                    <div class="txt"> {{setLessonInfo(item02)}}</div>
                    <i class="icon-radio" v-if="item02.isTryListen === 1">试听</i>
                  </div>
                  <div class="more" @click="getMore(index01,item01,item01.child[item01.child.length - 1])" v-if="item.child[index01].child.length < item.child[index01].child[0].total">加载更多<i class="icon-more"></i></div>
                </div>
              </template>
            </div>
          </template>
        </template>
      </div>
    </template>
    <template v-if="state.levelNum == 2">
      <!-- 只有二级 模块 - 课节 目录 -->
      <div v-for="(item ,index) in state.data" :key="index">
        <div class="tit-box" @click.stop="getLessonMore(item,index)">
          <p class="tit tit-01 f-owt-two">{{item.title}}</p>
          <i class="icon-radio" v-if="item.isTryListen === 1">试听</i>
          <i class="top-green" :class="{'top-green-01': item.show}"></i>
        </div>
        <template v-if="item.child">
          <div class="lesson-item" v-show="item.show">
            <div class="li" v-for="(item01 ,index01) in item.child" :key="index01">
              <!-- <i class="icon-dian"></i> -->
              <div class="name f-owt-two"><span class="lesson_name">{{index01+1}}</span>{{item01.title}}</div>
              <div class="txt"> {{setLessonInfo(item01)}}</div>
              <i class="icon-radio" v-if="item01.isTryListen === 1">试听</i>
            </div>
            <div class="more" @click="getMoreOne(index,item,item.child[item.child.length - 1])" v-if="state.data[index].child.length < state.data[index].child[0].total">加载更多<i class="icon-more"></i></div>
          </div>
        </template>
      </div>
    </template>
    <template v-if="state.levelNum == 1">
      <!-- 只有一级课节目录 -->
      <div class="lesson-item">
        <div v-for="(item ,index) in state.data" :key="index">
          <div class="li">
            <!-- <i class="icon-dian"></i> -->
            <div class="name f-owt-two"><span class="lesson_name">{{index+1}}</span>{{item.title}}</div>
            <div class="txt"> {{setLessonInfo(item)}}</div>
            <i class="icon-radio" v-if="item.isTryListen === 1">试听</i>
          </div>
        </div>
        <div class="more" @click="getMoreTwo(state.data[0])" v-if="state.data.length < state.data[0].total">加载更多<i class="icon-more"></i></div>
      </div>
    </template>
    <vantEmpty :show="state.empty_show" type="detail" text="暂无课程～"></vantEmpty>
  </div>
</template>
<script>
import { reactive, getCurrentInstance, watchEffect, onMounted } from 'vue'
export default {
  name: 'page-lesson',
  props: {
    courseItem: Object,
    courseData: Object,
    platform: '',
    goodsid: '',
    load: Boolean,
    source: String
  },
  setup(props, context) {
    const { proxy } = getCurrentInstance()
    const state = reactive({
      data: [], //课程目录数据
      platform: '', //课程归属
      goodsid: '', //商品ID
      empty_show: false, //空数据
      level: 1, //当前级别level
      stage_id: 0, //阶段ID
      modular_id: 0, //模块ID
      index: 0, //一级index
      index01: 0, //二级index
      moreData: 1, //3级当前页
      levelNum: 3
    })
    onMounted(() => {
      // oao课程初始化课程目录
      if (props.load) {
        getLesson() //获取课程目录
      }
    })

    // 获取课程目录
    const getLesson = () => {
      proxy.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '加载中...'
      })
      let params = {
        goodsNum: props.courseItem.code,
        page: state.moreData,
        pageSize: 5,
        level: state.level,
        stageId: state.stage_id,
        modularId: state.modular_id
      }
      proxy.$http
        .get(process.env.VUE_APP_XS_URL + '/api/course/goods/syllabus', {
          params
        })
        .then((res) => {
          proxy.$toast.clear()
          const body = res.data.data
          state.levelNum = body.levelNum
          const index = state.index
          const index01 = state.index01
          if (body.data.length > 0) {
            for (let i = 0; i < body.data.length; i++) {
              body.data[i].show = false
              body.data[i].total = body.total
              body.data[i].page = body.page
              body.data[i].pageCount = body.pageCount
            }
            if (res.data.code == 10000) {
              if (state.levelNum == 3) {
                if (body.currentLevel == 1) {
                  // 获取 - 大纲目录 ：接口一次性返回
                  state.data = body.data
                } else if (body.currentLevel == 2) {
                  // 获取 - 模块目录 ： 接口一次性返回
                  state.data[index].child = body.data
                } else if (body.currentLevel == 3) {
                  // 获取 - 课节目录 ： 接口翻页返回
                  if (state.moreData > 1) {
                    let list = state.data[index].child[index01].child.concat(
                      body.data
                    )
                    state.data[index].child[index01].child = list
                  } else {
                    state.data[index].child[index01].child = body.data
                  }
                }
              } else if (state.levelNum == 2) {
                if (body.currentLevel == 2) {
                  // 获取 - 模块目录 ： 接口一次性返回
                  state.data = body.data
                } else if (body.currentLevel == 3) {
                  // 获取 - 课节目录 ： 接口翻页返回
                  if (state.moreData > 1) {
                    let list = state.data[index].child.concat(body.data)
                    state.data[index].child = list
                  } else {
                    state.data[index].child = body.data
                  }
                }
              } else if (state.levelNum == 1) {
                if (body.currentLevel == 3) {
                  // 获取 - 课节目录 ： 接口翻页返回
                  if (state.moreData > 1) {
                    let stateData = JSON.parse(JSON.stringify(state.data))
                    let list = stateData.concat(body.data)
                    state.data = list
                  } else {
                    state.data = body.data
                  }
                }
              } else if (state.levelNum == 0) {
                state.empty_show = true
              }
            } else {
              proxy.$toast(res.data.msg)
            }
          }
        })
        .catch((error) => console.log(error))
    }

    // 获取二级模块信息
    const bindLessonChange01 = (item, index) => {
      if (!state.data[index].show) {
        state.level = item.level + 1
        state.index = index
        state.stage_id = item.id
        state.data[index].show = true
        state.page = 1
        getLesson()
      } else {
        state.data[index].show = false
      }
    }

    // 共三级情况下 获取三级信息
    const bindLessonChange02 = (data, index, index01) => {
      if (!state.data[index].child[index01].show) {
        state.level = data.level + 1
        state.stage_id = data.stageId
        state.modular_id = data.id
        state.index = index
        state.index01 = index01
        state.data[index].child[index01].show = true
        state.moreData = 1
        getLesson()
      } else {
        state.data[index].child[index01].show = false
      }
    }
    // 共二级情况下 获取三级信息
    const getLessonMore = (data, index) => {
      if (!state.data[index].show) {
        state.level = data.level + 1
        state.stage_id = data.stageId
        state.modular_id = data.id
        state.index = index
        state.data[index].show = true
        state.moreData = 1
        getLesson()
      } else {
        state.data[index].show = false
      }
    }
    // 加载更多
    const getMore = (index, data, item) => {
      console.log('===item', item)
      state.index01 = index
      state.stage_id = data.stageId
      state.modular_id = data.id
      state.moreData = item.page + 1
      getLesson()
    }
    // 二级情况下 加载更多
    const getMoreOne = (index, data, item) => {
      console.log('===index', index)
      console.log('===data', data)
      console.log('===item', item)
      state.index = index
      state.stage_id = data.stageId
      state.modular_id = data.id
      state.moreData = item.page + 1
      getLesson()
    }
    // 只有课节一级
    const getMoreTwo = (data) => {
      state.stage_id = data.stageId
      state.modular_id = data.id
      state.moreData = data.page + 1
      data.page = data.page + 1 //点击一次加载更多，page+1
      getLesson()
    }
    // 试听
    const bindAudition = (is_trial, url) => {
      console.log('试听', is_trial, url)
      if (props.source.indexOf('xcx') !== -1) {
        proxy.$toast('购买后请在对应的软件中听课哦～')
      } else if (is_trial == '1' && url) {
        window.location.href = url
      }
    }
    const setLessonInfo = (data) => {
      let info = data.teacherName
        ? data.teacherName + ' | ' + data.timeShow
        : data.timeShow
      return info
    }
    return {
      state,
      getLesson,
      getMore,
      bindLessonChange01,
      bindLessonChange02,
      bindAudition,
      setLessonInfo,
      getLessonMore,
      getMoreOne,
      getMoreTwo
    }
  }
}
</script>

<style scoped lang="scss" src="../../views/detail/index.scss"></style>