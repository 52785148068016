<template>
    <div class="main">
        <li class="sex-sel subject">
            <div class="sex title">
                <span class="pre">*</span>
                <span class="options">性别</span>
            </div>
            <div class="sex-sel-con selection">
                <div
                    class="item male"
                    :class="state.gender == 1 ? 'active' : ''"
                    @click="onGenderSel(1)"
                >
                    <i></i>
                    <span>男生</span>
                </div>
                <div
                    class="item female"
                    :class="state.gender == 0 ? 'active' : ''"
                    @click="onGenderSel(0)"
                >
                    <i></i>
                    <span>女生</span>
                </div>
            </div>
        </li>
        <li class="subject">
            <div class="title">
                <span class="pre">*</span>
                <span class="options">毕业年份</span>
            </div>
            <div class="selection" @click="onShowPicker(types.year)">
                <input :value="getInputValue(types.year)" class="sel-input" type="text" readonly="true" placeholder="请选择毕业年份" />
                <i class="triangle"></i>
            </div>
        </li>
        <li class="subject">
            <div class="title">
                <span class="pre">*</span>
                <span class="options">毕业院校</span>
            </div>
            <div class="selection" @click="onShowPicker(types.school)">
                <input :value="getInputValue(types.school)" class="sel-input" type="text" readonly="true" placeholder="请选择毕业院校" />
                <i class="triangle"></i>
            </div>
        </li>
        <li class="subject">
            <div class="title">
                <span class="pre">*</span>
                <span class="options">学历</span>
            </div>
            <div class="selection" @click="onShowPicker(types.degree)">
                <input :value="getInputValue(types.degree)" class="sel-input" type="text" readonly="true" placeholder="请选择学历" />
                <i class="triangle"></i>
            </div>
        </li>
        <div class="confirm-btn" @click="onClickConfirm">
            立即领取
        </div>
    </div>
    <van-popup :show="state.showPicker !== false" round position="bottom">
        <van-picker
            :title="state.showPicker == types.year ? '毕业年份' : (state.showPicker == types.degree ? '学历' : '毕业院校')"
            show-toolbar
            :columns="getOptonsList(state.showPicker)"
            @cancel="state.showPicker = false"
            @confirm="onConfirm"
        />
    </van-popup>
</template>

<script>
import { 
    getCurrentInstance, 
    onMounted, 
    reactive
} from "vue";
import { isEmptyObject } from '../../utils/util.js';
import { useStore } from 'vuex';
export default {
    props: {
        type: String
    },
    setup() {
        const { proxy } = getCurrentInstance();
        const store = useStore();
        const state = reactive({
            gender: 1, // 1表示男生，0表示女生
            showPicker: false
        });

        const optionLists = {
            gender: [],
            year: [],
            degree: [],
            school: []
        }

        const types = {
            year: 'year',
            school: 'school',
            degree: 'degree'
        };

        const selOptions = {}

        const receiveData = {
            gender: 1,
            year: '',
            degree: '',
            school: ''
        }

        onMounted(() => {
            // 获取学历学校等数据
            getConfirmInfo();
        });

        const getConfirmInfo = () => {
            proxy.$http.get('/api/course/graduation')
            .then(res => {
                if(res.data.code == 200) {
                    ({
                        gender: optionLists.gender, 
                        year: optionLists.year, 
                        school: optionLists.school, 
                        degree: optionLists.degree
                    } = res.data.data);
                    optionLists.school = optionLists.school.map(item => {
                        return item.name;
                    });
                    optionLists.year.sort((a, b) => {
                        return b - a;
                    });
                } else {
                    console.log('fail: ', res.data.message);
                }
            })
            .catch(err => {
                console.log('err:', err);
            });
        }

        const onGenderSel = (gender) => {
            state.gender = gender;
        };

        const getOptonsList = (type) => {
           return optionLists[type];
        }

        const getInputValue = (type) => {
            if(isEmptyObject(selOptions))
                return;
            return selOptions[type];
        }

        const onConfirm = (data) => {
            selOptions[state.showPicker] = data;
            state.showPicker = false;
        }
        const onShowPicker = (type) => {
            state.showPicker = type
        }

        const onClickConfirm = () => {
            if(toastWithType()) {
                return;
            }
            receiveData.gender = state.gender;
            Object.assign(receiveData, selOptions);
            receiveData.cTime = new Date().getTime();
            store.commit('globalData/addReceiveConfirm', receiveData);
        }

        // 判断是否选了必填项
        const toastWithType = () => {
            let msg = null;
            if(!selOptions.year || selOptions.year == '') {
                msg = '毕业年份必填'
            } else if(!selOptions.school || selOptions.school == '') {
                msg = '学校必填'
            } else if(!selOptions.degree || selOptions.degree == '') {
                msg = '学历必填'
            }
            msg && proxy.$toast({
                message: msg,
                // overlay: true
            });
            return msg;
        }

        return {
            state,
            types,
            onGenderSel,
            onConfirm,
            onShowPicker,
            onClickConfirm,
            getOptonsList,
            getInputValue
        };
    },
};
</script>

<style lang="scss" scoped>
.main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 14px;
    .subject {
        margin-bottom: 20px;
        .title {
            flex-grow: 1;
            margin-left: 15px;
            display: flex;
            align-items: center;
            .pre {
                color: #f00;
                margin-right: 5px;
            }
            .options {
                font-size: 12px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #999;
            }
        }
        .selection {
            width: 223px;
            height: 30px;
            background: #f8f8f8;
            border-radius: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .triangle{
                background: url(https://file.huatu.com/static/miniprogram/shop/h5/pop/icon-triangle-down@2x.png) no-repeat 0 0;
                background-size: cover;
                width: 9px;
                height: 9px;
                margin-right: 16px;
            }
            .sel-input{
                background: transparent;
                flex-grow: 1;
                align-items: center;
                padding-left: 15px;
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
            }
            .sel-input::-webkit-input-placeholder{
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999 !important;
            }
        }
        &:last-child{
            margin-bottom: 24px;
        }
    }
    .sex-sel {
        .sex-sel-con {
            display: flex;
            align-items: center;
            .item {
                width: 50%;
                height: 100%;
                border-radius: 15px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                transition: all 0.1s ease;
                i {
                    width: 13px;
                    height: 13px;
                    margin-right: 1px;
                }
            }
            .item.active {
                color: #fff;
                background: linear-gradient(90deg, #ff9d5b 0%, #fc6c21 100%);
            }
            .male i {
                background: url(https://file.huatu.com/static/miniprogram/shop/h5/pop/icon-male-normal@2x.png)
                    no-repeat 0 0;
                background-size: cover;
            }
            .male.active i {
                background: url(https://file.huatu.com/static/miniprogram/shop/h5/pop/icon-male-active@2x.png)
                    no-repeat 0 0;
                background-size: cover;
            }
            .female i {
                background: url(https://file.huatu.com/static/miniprogram/shop/h5/pop/icon-female-normal@2x.png)
                    no-repeat 0 0;
                background-size: cover;
            }
            .female.active i {
                background: url(https://file.huatu.com/static/miniprogram/shop/h5/pop/icon-female-active@2x.png)
                    no-repeat 0 0;
                background-size: cover;
            }
        }
    }
    .confirm-btn{
        width: 223px;
        height: 40px;
        background: url(https://file.huatu.com/static/miniprogram/shop/h5/pop/bg-confirm-btn@2x.png) no-repeat 0 0;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 36px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
    }
}
/deep/ .van-picker__confirm{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FC6C21;
}
/deep/ .van-picker__cancel{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #969799;
}
/deep/ .van-picker__title{
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #323233;
}
</style>