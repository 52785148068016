const state = {
    shop_id: '',//店铺ID
    group_share: false,//拼团详情唤起分享弹窗
    ly: '',
    spread_url: '',
    receive_id: -1, // 领取的课程id，若为-1，则表示该用户未领取课程
    receiveConfirm: '', // 触发立即领取事件的时间
    homeScrollTop: 0, // 首页滚动scrollTop，用于从课程详情页返回时的列表复位

    shopInfo:{ //店铺信息
        title:"",
        description:""
    }
}
const getters = {
    getShopInfo :state=>{
        return state.shopInfo;
    },
}
const mutations = {
    addShopId(state, shop_id) {
        state.shop_id = shop_id;
    },
    addShare(share) {
        state.group_share = share;
    },
    addLy(satte, ly) {
        state.ly = ly;
    },
    addUrl(satte, url) {
        state.spread_url = url
    },
    addReceiveId(state, rId) {
        state.receive_id = rId;
    },
    addReceiveConfirm(state, rtData) {
        state.receiveConfirm = rtData;
    },
    addHomeScrollTop(state, st) {
        state.homeScrollTop = st;
    },
    changeShopInfo(state,obj){
        state.shopInfo=obj;
    }
}
const actions = {
    asyncShopInfo({ commit }, obj){
        commit("changeShopInfo", obj)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
