<template>
    <div class="box" v-if="show">
        <div class="bg"></div>
        <div class="empty">
            <img src="../../assets/image/empty/address-404.png" class="img" v-if="type == 'address'">
            <img src="../../assets/image/empty/detail-404.png" class="img" v-if="type == 'detail'">
            <img src="../../assets/image/empty/order-404.png" class="img" v-if="type == 'order'">
            <img src="../../assets/image/empty/search-404.png" class="img" v-if="type == 'search'">
            <div class="txt">{{text}}</div>
            <div class="tel" v-if='linkTel'><a :href="'tel:'+linkTel">{{linkTel}}</a></div>
            <div class="btn" @click="bindBtn" v-if="btnText && btnShow">{{btnText}}</div>
        </div>
    </div>
</template>

<script>
import { getCurrentInstance, watchEffect} from "vue";
import { useRouter, useRoute } from "vue-router";
export default {
	name: "empty",
    props: {
        show: Boolean,
        text: String,
        btnShow: Boolean,
        btnText: String,
        type: String,
        linkTel:String
    },
	setup(props,context) {
        const { proxy } = getCurrentInstance();

        watchEffect(() => {
            // console.log("数据监听===>", props.type);
        })

        const bindBtn = () => {
            context.emit('bindBtn');
        };

		return {
            bindBtn
		}
	}
};
</script>

<style scoped lang="scss">
.box{
    height: 300px;
    background: #ffffff;
    position: relative;
    .bg{
        background: #ffffff;
        height: 50px;
        position: absolute;
        top: -50px;
        left: 0;
        right: 0;
    }
}
.empty{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	.img{
		width: 119px;
		height: 114px;
		margin-top: 20px;
	}
	.txt{
		font-size: 14px;
		color: #999999;
		margin-top: 24px;
		line-height: 20px;
	}
    .tel{
		margin-top: 10px;
		line-height: 20px;
        a{
            font-size: 14px;
            color: #1989fa;
        }
    }
    .btn{
        width: 140px;
        height: 38px;
        color: #ffffff;
        font-weight: bold;
        line-height: 20px;
        font-size: 14px;
        background: linear-gradient(137deg, #FF9D5B 0%, #FC6C21 100%);
        box-shadow: 0px 0px 9px 0px rgba(96, 105, 142, 0.06);
        border-radius: 22px;
        text-align: center;
        line-height: 38px;
        margin-top: 24px;
    }
}
</style>