import { param } from "jquery"

// 存储localStorage
export const setStore = (name, content) => {
  if (!name) return
  localStorage.setItem(name, JSON.stringify(content))
}

// 获取localStorage
export const getStore = name => {
  if (!name) return
  return JSON.parse(localStorage.getItem(name))
}

export function removeSpace(value) {
  return value.replace(/\s+/g, '')
}

export function formValidate(val, type) {
  const phoneReg = /(^1[3|4|5|7|8]\d{9}$)|(^09\d{8}$)/
  const emailReg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/
  if (val === '') {
    return false
  } else {
    // 非空验证
    if (type === 'require') {
      return !!removeSpace(val)
    }
    if (type === 'phone') {
      return phoneReg.test(val)
    }
    if (type === 'email') {
      return emailReg.test(val)
    }
  }
}

// 获取url参数
export function getUrlKey(name) {
  return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
}

// 数组去重
export function dedupe(array) {
  return Array.from(new Set(array))
}

// 判断是否为空对象'{}'
export function isEmptyObject(obj) {
  if(!obj || obj === undefined) {
      return true;
  }
  if(typeof obj !== 'object') {
    return false;
  }
  return JSON.stringify(obj) === '{}';
}
// 单变双位number
export function formmateNumToDoubleString(num) {
    num = parseInt(num);
    return num < 10 ? '0' + num : num + '';
}

export const ModalHelper = ((bodyCls) => {
  let scrollTop
  return {
    afterOpen: function () {
      scrollTop = document.scrollingElement.scrollTop
      document.body.classList.add(bodyCls)
      document.body.style.top = -scrollTop + 'px'
    },
    beforeClose: function () {
      document.body.classList.remove(bodyCls)
      // scrollTop lost after set position:fixed, restore it back.
      document.scrollingElement.scrollTop = scrollTop
    }
  }
})('dialog-open')

/**
 * 神策track封装
*/
export const sensorTrack = ({eventName, params}) => {
    let platforms = ['学习平台', '', '华图在线', '神2', '运营中台', '神2'],
        depatments = ['未知','公职事业部','在职培训部','教师事业部','事业单位事业部','金融事业部','医疗卓坤事业部','部队事业部','政法项目部','学历教育事业部','考研事业部'],
        types = ['直播', '录播', '线下体验课', '面授课', 'OAO', '图书', '在线模考', '合集'];
    return new Promise((resolve, reject) => {
        if(!sa || !sa.track) {
            resolve(-1);
        } else {
            for (const key in params) {
                if (Object.hasOwnProperty.call(params, key)) {
                    key == 'course_source' && (params[key] = platforms[params[key]] || '');
                    key == 'business_unit' && (params[key] = depatments[params[key]] || '');
                    key == 'teach_way' && (params[key] = types[params[key]] || '');
                    key == 'promoter_phone' && (params[key] = getStore('EHR_INFO_DATA').mobile || '');
                    key == 'ehr_account' && (params[key] = getStore('EHR_INFO_DATA').ehr_uname || '');
                }
            }
            sa.track(eventName, params, () => {
                resolve(1);
            })
        }
    })
}

/**
 * 封装store addShopId，用于调用接口返回店铺对应推广员信息
*/
export const commitAddShopId = (store, proxy, shop_id) => {
    store.commit("globalData/addShopId", shop_id);
    proxy.$http.get(`/api/shop/salesman/${shop_id}`)
    .then(res => {
        let body = res.data;
        if(body.code == 200) {
            setStore('EHR_INFO_DATA', body.data);
        } else {
            console.log('/api/shop/salesman failed!', body.message);
        }
    }).catch(err => {
        console.log('/api/shop/salesman Error:', err);
    })
}

/**
 * 高德地图api -> 获取地理位置信息，enableHighAccuracy == true 高精度
*/
export const getGeoLocation = () => {
    return new Promise((resolve, reject) => {
        if(!AMap) {
            reject(-1);
        }
        AMap.plugin('AMap.Geolocation', function() {
            let geoloaction = new AMap.Geolocation({
                enableHighAccuracy: true,
                timeout: 2000
            });
            geoloaction.getCurrentPosition();
            AMap.event.addListener(geoloaction, 'complete', onComplete);
            AMap.event.addListener(geoloaction, 'error', onError);
    
            function onComplete(res) {
                resolve(res);
            }
    
            function onError(err) {
                console.log('err:', err);
                reject(err);
            }
        });
    })

    
}

/**
 * 高德地图api -> 获取所在城市地理位置信息，低精度
*/
export const getLngLatLocation = () => {
    if(!AMap) {
        return false;
    }
    AMap.plugin('AMap.CitySearch', function() {
        let citySearch = new AMap.CitySearch();
        citySearch.getLocalCity((status, result) => {
            console.log('status:', status);
            console.log('result:', result);
        });
    });
}