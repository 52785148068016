<template>
    <div class="pattern" :class="[type == 'pop' ? '' : 'other']">
        <div class="item">
            <div class="bage">
                <span>方式一</span>
            </div>
            <div class="container">
                <div class="section">
                    登录：<span class="hightlight">xue.huatu.com</span>在我的课程中学习；
                </div>
            </div>
        </div>
        <div class="item">
            <div class="bage">
                <span>方式二</span>
            </div>
            <div class="container">
                <div class="tips">微信搜索“华图教育”进行查找；</div>
                <!-- <div v-if="broswer == 1" class="box-htjy">
                    <wx-open-launch-weapp id="launch-btn" :username="username" :path="jump_wx_path" style="width: 100%;height:100%;display:block;">
                        <div v-is="'script'" type="text/wxtag-template">
                            <img :src="image" style="width: 100%;">
                        </div>
                    </wx-open-launch-weapp>
                </div> -->
                <div class="box-htjy">
                    <img src="https://file.huatu.com/static/miniprogram/shop/h5/pop/bg-pop-drainage-htjy@2x.png" style="width: 100%;">
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import { getCurrentInstance, nextTick, reactive, ref, toRefs, watchEffect, computed, onMounted } from 'vue';
// import QRCode from "qrcodejs2";
// import { isEmptyObject } from '../../utils/util';
export default {
    name: "pop-drainage",
    props: {
        type: String, // 默认为other，弹窗时为pop
    },
    setup(props, ctx){
        const { proxy } = getCurrentInstance();
        const qrcode = ref(null);
        const state = reactive({
            xcxHtml: '',
            qrcode_picture: '',
            qrType: 'URL',
            jump_wx_path: '',
            broswer: '',//1-微信浏览器2-其他浏览器
        });

        onMounted(() => {
            isWeiXin();//是否为微信浏览器
        });

        // const qrcode_show_pic = computed(() => {
        //     return state.qrType === 'PIC';
        // });

        // watchEffect(() => {
        //     if(!isEmptyObject(props.mockData)) {
        //         nextTick(() => {
        //             getQrcode();
        //         });
        //         console.log('props.mockData:', props.mockData);
        //         state.jump_wx_path = `/pages/home/index.html?categoryId=${props.mockData.item_id}&subjectId=${props.mockData.mock_type}&areaId=${props.mockData.is_register}&m=${props.mockData.mobile_format}&source=3&name=${props.mockData.item_name}-${props.mockData.mock_area[props.mockData.is_register]}`;
        //     }
        // });

        // const getQrcode = () => {
        //     proxy.$http.get(`/api/mock/qrcode/${props.mockData.ehr_code}`)
        //     .then(res => {
        //         if(res.data.code == 200){
        //             let qrcode_url = res.data.data.qrcode;
        //             if(qrcode_url != '') {
        //                 /.png$/.test(qrcode_url) ? (state.qrcode_picture = qrcode_url, state.qrType = 'PIC') : (drawQrcodeWithURL(qrcode_url), state.qrType = 'URL');
        //             }
        //         } else {
        //             proxy.$toast(res.data.message)
        //         }
        //     })
        //     .catch(err => {
        //         console.log('err:', err);
        //     });
        // }

        // const drawQrcodeWithURL = (url) => {
        //     qrcode.value.innerHTML = '';
        //     var qrcodeObj = new QRCode(qrcode.value, {
        //         width: 66,
        //         height: 66, // 高度
        //     })
        //     qrcodeObj.makeCode(url);
        //     var canvas = $(qrcode.value).find('canvas').get(0);
        //     $("#qr_img>img").attr("src",canvas.toDataURL('image/jpg')); 
        // }

        //判断是否是微信浏览器的函数
        const isWeiXin = () => {
            //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
            var ua = window.navigator.userAgent.toLowerCase();
            //通过正则表达式匹配ua中是否含有MicroMessenger字符串
            if(ua.match(/MicroMessenger/i) == 'micromessenger'){
                state.broswer = '1';
            } else {
                state.broswer = '2';
            }
        }

        return {
            ...toRefs(state),
            // qrcode,
            // qrcode_show_pic
        }
    }
}
</script>

<style scoped lang="scss">
.pattern{
    width: 100%;
    padding: 15px 0;
    overflow: hidden;
    background: #FFF4ED;
    border-radius: 8px;
    .item {
        width: 92%;
        margin: 0 auto;
        background: #fff;
        border-radius: 8px;
        position: relative;
        display: flex;
        &:first-child{
            margin-bottom: 12px;
        }
        .container{
            width: 100%;
            height: 100%;
            border-radius: 8px;
            overflow: hidden;
            flex-grow: 1;
            box-sizing: border-box;
            .tips{
                font-size: 13px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #666666;
                margin-top: 27px;
                margin-left: 9px;
                margin-bottom: 3px;
            }
            .section{
                box-sizing: border-box;
                // padding: 0 10px;
                margin-top: 26px;
                margin-bottom: 3px;
                font-size: 13px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #666666;
                margin: 27px 9px 16px;
                .hightlight{
                    color: #FC6C21;
                }
                .two {
                    margin-right: 0px;
                    span.color1{
                        color: #FC6C21;
                    }
                }
                .qrcode{
                    width: 66px;
                    height: 66px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .qrcode_img {
                    flex-shrink: 0;
                    width: 66px;
                    height: 66px;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            .subTips{
                -webkit-transform-origin-x: 0;
                -webkit-transform: scale(0.90);
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                // margin-left: 9px;
                padding-left: 10px;
                width: 110%;
                box-sizing: border-box;
                &::before{
                    content: '';
                    width: 4px;
                    height: 4px;
                    background-image: url(https://file.huatu.com/static/miniprogram/shop/h5/mock/icon-mock-coin.png);
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: 0 0;
                    margin-right: 5px;
                }
            }
            .arraw{
                display: flex;
                justify-content: center;
                align-items: flex-start;
                img{
                    width: 9px;
                    height: 21px;
                }
            }
            .box-htjy{
                width: 88.43%;
                height: 141px;
                margin: 0 auto;
                margin-top: 5px;
                margin-bottom: 16px;
                box-shadow: 0px 2px 8px 0px rgba(252, 108, 33, 0.12);
            }
        }
        .bage{
            width: 48px;
            height: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: url(https://file.huatu.com/static/miniprogram/shop/h5/mock/icon-mock-pop-pattern-item.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 0 0;
            position: absolute;
            left: 0;
            top: 0;
            font-size: 11px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #fff;
            span{
                padding-bottom: 3px;
            }
        }
    }
}
</style>