<template>
  <router-view></router-view>
</template>

<script>
import { onMounted, getCurrentInstance } from '@vue/runtime-core'
import { getStore } from '@/utils/util'
import { useRoute } from 'vue-router'
import WxShare from '@/utils/wx_share'
import { useStore } from 'vuex'

export default {
  name: 'App',
  setup() {
    const store = useStore()
    const $route = useRoute()
    const { proxy } = getCurrentInstance()

    let flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
    if (!flag) {
      // 如果是pc
      var style = document.createElement('style')
      style.type = 'text/css'
      style.innerHTML = 'html,body{ width:570px !important; }'
      document.getElementsByTagName('HEAD').item(0).appendChild(style)
    }

    let shopId

    onMounted(() => {
      setTimeout(() => {
        // 获取shop_id
        if (getStore('shop_id')) {
          shopId = getStore('shop_id')
        }

        // 当前url参数的shop_url
        if ($route.params.shop_id) {
          shopId = $route.params.shop_id
        }

        console.log('shopIdshopId', shopId)
        // 获取店铺信息
        proxy.$http
          .get(`/api/shop/info/${shopId}`)
          .then((res) => {
            console.log(res.data)
            let obj = res.data
            if (obj.code == 200) {
              if (window.location.pathname.indexOf('/detail/') != 0) {
                // 说明是课程详情页面
                WxShare({
                  shareTitle: obj.data.shop_name,
                  shareDesc: obj.data.shop_profile,
                  shareUrl: window.location.href,
                  shareImg: ''
                })
              }
              //存入vuex
              store.dispatch('globalData/asyncShopInfo', {
                title: obj.data.shop_name,
                description: obj.data.shop_profile
              })
            } else {
              if (window.location.pathname.indexOf('/detail/') != 0) {
                WxShare()
              }
            }
          })
          .catch(() => {
            if (window.location.pathname.indexOf('/detail/') != 0) {
              WxShare()
            }
          })
      }, 500)
    })
  }
}
</script>
<style lang="scss">
@import './styles/base.scss';
@import './common/font.css';
html,
body,
#app {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: #f9f9f9;
  -webkit-overflow-scrolling: touch;
}
</style>

