<template>
    <div class="wuliu">
        <van-popup :show="show" @click-overlay="bindPop" round>
            <div class="box">
                <div class="top">
                    <i class="icon-tree"></i>
                    <span class="title">查看物流</span>
                </div>
                <div class="content">
                    <div class="tracking-number" @click.stop="copy(wuliuData.extra.express_no)" v-if="wuliuData.data.length>0">{{wuliuData.extra.express_com}}快递单号：{{wuliuData.extra.express_no}} <i class="icon-copy"></i></div>
                    <div class="item" v-for="(item ,index) in wuliuData.data" :key="index" :class="{'item-01': index>0}">
                        <div class="lt">
                            <div class="date">{{item.date}}</div>
                            <div class="time">{{item.time}}</div>
                        </div>
                        <div class="rt">
                            <i class="icon-gou" v-if="index==0"></i>
                            <i class="icon-ou" v-else></i>
                            <div class="name">{{item.context}}</div>
                        </div>
                    </div>
                    <vantEmpty :show="wuliu_empty_show" type="order" text="暂无物流信息～"></vantEmpty>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { getCurrentInstance } from "vue";
import useClipboard from 'vue-clipboard3';
export default {
	name: "wuliu",
    props: {
        show: Boolean,
        wuliuData: Object,
        wuliu_empty_show: Boolean
    },
	setup(props,context) {
        const { proxy } = getCurrentInstance();
        // 点击遮罩层
        const bindPop = () => {
            context.emit('bindPop');
        }

        // 复制订单号
        const { toClipboard } = useClipboard()
        const copy = async (Msg) => {
            try {
                //复制
                await toClipboard(Msg)
                proxy.$toast("复制成功");
            } catch (e) {
                //复制失败
                proxy.$toast("复制失败");
            }
        }

		return {
            bindPop,
            copy,
		}
	}
};
</script>

<style scoped lang="scss">
.box{
    width: 311px;
    height: 435px;
    .top{
        height: 50px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 17px;
        background: url(../../assets/image/wuliu/bg-wuliu.png) center/100% 100% no-repeat;
        .icon-tree{
           display: block;
           width: 2px;
           height: 14px;
           background: #FC6C21; 
           margin-right: 6px;
        }
    }
    .content{
        padding-bottom: 20px;
        height: 385px;
        overflow-y: auto;
        .tracking-number{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 12px 15px;
            font-size: 13px;
            .icon-copy{
                display: block;
                width: 12px;
                height: 12px;
                margin-left: 5px;
                background: url(../../assets/icons/icon-copy.png) center/100% 100% no-repeat;
            }
        }
        .item{
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            .lt{
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-start;
                padding: 0 15px;
                .date{
                    font-size: 13px;
                    line-height: 17px;
                    text-align: end;
                    white-space: nowrap;
                }
                .time{
                    font-size: 10px;
                    line-height: 14px;
                    padding-top: 3px;
                    text-align: end;
                }
            }
            .rt{
                font-size: 13px;
                line-height: 18px;
                padding: 0 15px 23px 15px;
                border-left: 1px dashed #8B8B8B;
                width: 245px;
                position: relative;
                .icon-gou{
                    display: block;
                    width: 13px;
                    height: 13px;
                    background: url(../../assets/icons/icon-gou.png) center/100% 100% no-repeat;
                    background-color: #ffffff;
                    position: absolute;
                    left: -7px;
                    top: -1px;
                }
                .icon-ou{
                    display: block;
                    width: 7px;
                    height: 7px;
                    background: #8B8B8B;
                    position: absolute;
                    left: -4px;
                    top: 4px;
                    border-radius: 50%;
                }
            }
        }
        .item-01{
            color: #8B8B8B;
        }
    }
}
</style>