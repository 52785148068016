<template>
    <div class="pattern" :class="[type == 'pop' ? '' : 'other']">
        <div class="header" v-if="type == 'other'">考试方式：</div>
        <div class="item" v-if="!noQrcode">
            <div class="bage">
                <span>入群</span>
            </div>
            <div class="container">
                <div class="section">
                    <div class="tips">
                        长按识别下方二维码，加入模考大赛官方交流群，得到班主任老师专业全程服务；
                    </div>
                    <div v-if="!qrcode_show_pic" v-show="false" ref="qrcode" class="qrcode"></div>
                    <div v-if="!qrcode_show_pic" id="qr_img"><img src='' alt=''/></div>
                    <div v-else class="qrcode_img">
                        <img :src="qrcode_picture" alt=""/>
                    </div>
                </div>
                <div class="subTips">
                    若无法长按识别您可以截图或者下载后微信识别哦~
                </div>
            </div>
        </div>
        <div class="item">
            <div class="bage">
                <span>模考</span>
            </div>
            <div class="container">
                <div class="section">
                    <div class="tips two">
                        通过华图智题库小程序参加考试，
                        <span class="color1">点击打开</span>
                        下方小程序即可进入；
                        <span v-if="broswer != 1">(仅在微信环境内跳转)</span>
                    </div>
                </div>
                <div class="arraw">
                    <img src="https://file.huatu.com/static/miniprogram/shop/h5/mock/icon-mock-down-arraw.png" alt="">
                </div>
                <div v-if="broswer == 1" class="box-htjy">
                    <wx-open-launch-weapp id="launch-btn" :username="username" :path="jump_wx_path" style="width: 100%;height:100%;display:block;">
                        <div v-is="'script'" type="text/wxtag-template">
                            <img :src="image" style="width: 100%;">
                        </div>
                    </wx-open-launch-weapp>
                </div>
                <div v-else class="box-htjy">
                    <img :src="image" style="width: 100%;">
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import { getCurrentInstance, nextTick, reactive, ref, toRefs, watchEffect, computed, onMounted } from 'vue';
import QRCode from "qrcodejs2";
import { isEmptyObject } from '../../utils/util';
export default {
    name: "mock-drainage",
    props: {
        type: String, // 默认为other，弹窗时为pop
        username: String,
        path: String,
        image: String,
        mockData: Object
    },
    setup(props, ctx){
        const { proxy } = getCurrentInstance();
        const qrcode = ref(null);
        const state = reactive({
            xcxHtml: '',
            qrcode_picture: '',
            qrType: 'URL',
            jump_wx_path: '',
            broswer: '',//1-微信浏览器2-其他浏览器
            noQrcode: true
        });

        onMounted(() => {
            isWeiXin();//是否为微信浏览器
        });

        const qrcode_show_pic = computed(() => {
            return state.qrType === 'PIC';
        });

        watchEffect(() => {
            if(!isEmptyObject(props.mockData)) {
                nextTick(() => {
                    getQrcode();
                });
                state.jump_wx_path = `/pages/home/index.html?categoryId=${props.mockData.item_id}&subjectId=${props.mockData.mock_type}&areaId=${props.mockData.is_register}&m=${props.mockData.mobile_format}&source=3&name=${props.mockData.item_name}-${props.mockData.mock_area[props.mockData.is_register]}`;
                if(props.mockData.subject_id == 8) { // 国考
                    state.jump_wx_path = `/pages/home/index.html?categoryId=${props.mockData.item_id}&subjectId=${props.mockData.mock_type}&areaId=-9&m=${props.mockData.mobile_format}&source=3&name=${props.mockData.item_name}-全国`;
                }
                console.log(state.jump_wx_path);
            }
        });

        const getQrcode = () => {
            proxy.$http.get(`/api/mock/qrcode/${props.mockData.ehr_code}`)
            .then(res => {
                if(res.data.code == 200){
                    let qrcode_url = res.data.data.qrcode;
                    if(qrcode_url != '') {
                        state.noQrcode = false;
                        /(.png|.jpg|.jpeg)$/.test(qrcode_url) ? (state.qrcode_picture = qrcode_url, state.qrType = 'PIC') : (drawQrcodeWithURL(qrcode_url), state.qrType = 'URL');
                    } else {
                        state.noQrcode = true;
                    }
                } else {
                    proxy.$toast(res.data.message)
                }
            })
            .catch(err => {
                console.log('err:', err);
            });
        }

        const drawQrcodeWithURL = (url) => {
            nextTick(() => {
                qrcode.value.innerHTML = '';
                var qrcodeObj = new QRCode(qrcode.value, {
                    width: 66,
                    height: 66, // 高度
                })
                qrcodeObj.makeCode(url);
                var canvas = $(qrcode.value).find('canvas').get(0);
                $("#qr_img>img").attr("src",canvas.toDataURL('image/jpg'));
            })
        }

        //判断是否是微信浏览器的函数
        const isWeiXin = () => {
            //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
            var ua = window.navigator.userAgent.toLowerCase();
            //通过正则表达式匹配ua中是否含有MicroMessenger字符串
            if(ua.match(/MicroMessenger/i) == 'micromessenger'){
                state.broswer = '1';
            } else {
                state.broswer = '2';
            }
        }

        return {
            ...toRefs(state),
            qrcode,
            qrcode_show_pic
        }
    }
}
</script>

<style scoped lang="scss">
.pattern{
    width: 100%;
    // height: 341px;
    // padding: 15px 0;
    padding: 0px 0px 15px;
    overflow: hidden;
    .header{
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-bottom: 5px;
        box-sizing: border-box;
        padding-left: 4%;
    }
    .item {
        width: 92%;
        margin: 0 auto;
        background: #fff;
        border-radius: 8px;
        position: relative;
        
        // overflow: hidden;
        &:first-child{
            margin-bottom: 12px;
        }
        .container{
            width: 100%;
            height: 100%;
            border-radius: 8px;
            overflow: hidden;
            .section{
                box-sizing: border-box;
                padding: 0 10px;
                margin-top: 26px;
                margin-bottom: 3px;
                display: flex;
                justify-content: space-between;
                .tips{
                    font-size: 14px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #666666;
                    margin-right: 8px;
                }
                .two {
                    margin-right: 0px;
                    span.color1{
                        color: #FC6C21;
                    }
                    
                }
                .qrcode{
                    width: 66px;
                    height: 66px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .qrcode_img {
                    flex-shrink: 0;
                    width: 66px;
                    height: 66px;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            .subTips{
                -webkit-transform-origin-x: 0;
                -webkit-transform: scale(0.90);
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                // margin-left: 9px;
                padding-left: 10px;
                width: 110%;
                box-sizing: border-box;
                &::before{
                    content: '';
                    width: 4px;
                    height: 4px;
                    background-image: url(https://file.huatu.com/static/miniprogram/shop/h5/mock/icon-mock-coin.png);
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: 0 0;
                    margin-right: 5px;
                }
            }
            .arraw{
                display: flex;
                justify-content: center;
                align-items: flex-start;
                img{
                    width: 9px;
                    height: 21px;
                }
            }
            .box-htjy{
                width: 88.43%;
                height: 86px;
                margin: 0 auto;
                margin-top: 5px;
                margin-bottom: 16px;
                box-shadow: 0px 2px 8px 0px rgba(252, 108, 33, 0.12);
            }
        }
        .bage{
            width: 48px;
            height: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: url(https://file.huatu.com/static/miniprogram/shop/h5/mock/icon-mock-pop-pattern-item.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 0 0;
            position: absolute;
            left: 0;
            top: 0;
            font-size: 11px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #fff;
            span{
                padding-bottom: 3px;
            }
        }
    }
}
.other{
    background: #fff;
    padding-bottom: 4px;
    .item{
        background: #FFF9F6;
        margin-bottom: 12px;
        &:last-child{
            margin-bottom: 16px;
            .container{
                padding-bottom: 19px;
                .box-htjy{
                    margin-bottom: 0;
                }
            }
        }
    }
}
</style>