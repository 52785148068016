import { setStore } from "../../utils/util";
const state = {
  userInfo: {},
  access_token: ""
}
const getters = {}
const mutations = {
  setUser(state, userInfo) { 
    state.userInfo = userInfo
    setStore('userInfo', userInfo)
  },
  addUser(state, userInfo) { 
    state.userInfo = userInfo
  },
  setAccessToken(state, access_token) { 
    state.access_token = access_token
    setStore('access_token', access_token)
  },
  addAccessToken(state, access_token) { 
    state.access_token = access_token
  },
}
const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
