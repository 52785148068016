<template>
    <div class="detail-data">
        <template v-if="state.ziliaoData.list">
            <div class="data-item" v-for="(item ,index) in state.ziliaoData.list" :key="index">
                <div class="bt">
                    <i class="icon-pdf"></i>
                    <div class="txt">{{item.title}}</div>
                </div>
            </div>
            <div class="more" @click="getMore()" v-if="state.ziliaoData.next > 0">加载更多<i class="icon-more"></i></div>
        </template>
        <template v-else>
            <div class="data-item" v-for="(item ,index) in state.ziliaoData" :key="index">
                <div class="tit">{{item.title}}</div>
                <div class="bt" v-for="(itemChild ,index) in item.list" :key="index">
                    <i class="icon-pdf"></i>
                    <div class="txt">{{itemChild.title}}</div>
                </div>
            </div>
            <div class="more" @click="getMore()" v-if="state.ziliaoData.next > 0">加载更多<i class="icon-more"></i></div>
        </template>
        <vantEmpty :show="state.empty_show" type="detail" text="暂无资料～"></vantEmpty>
    </div>
</template>

<script>
import { reactive, getCurrentInstance, onMounted, watchEffect} from "vue";
export default {
	name: "detail-data",
    props: {
        platform: '',
        goodsid: '',
        course_id: String,
    },
	setup(props) {
        const { proxy } = getCurrentInstance();
        const state = reactive({
            ziliaoData: [],//课程资料
            page: 1,
            empty_show: false,
            platform:'',//课程归属
            goodsid:'',//商品ID
            course_id:'',//课程ID
        });

        watchEffect(() => {
            // console.log("获取资料===>",props.platform,props.goodsid,props.course_id);
            
            if(props.platform !== undefined){
               state.platform = props.platform;
            }
            if(props.goodsid !== undefined){
               state.goodsid = props.goodsid;
            }
            if(props.course_id !== undefined){
               state.course_id = props.course_id;
            }
        })

        onMounted(() => {
            getData(1);//获取资料
        })

        // 获取资料
	    const getData = (page) => {
            proxy.$toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: "加载中..."
            });
            if(page == 1){
                state.ziliaoData = [];
                state.page = page;
            }
            proxy.$http.get("/api/course/material",{
                    params: {
                        cid: (state.platform=='3' || state.platform=='4' || state.platform=='5' || state.platform=='6') ? state.goodsid:state.course_id,//区分神2课程ID,
                        platform : state.platform,
                        page: state.page
                    }
                })
                .then(res => {
                    const body = res.data.data;
                    proxy.$toast.clear();

                    if(res.data.code == '200'){
                        if(state.page > 1){
                            let list = state.ziliaoData.list.concat(body.list);
                            state.ziliaoData = body;
                            state.ziliaoData.list = list;
                        } else {
                            state.ziliaoData = body;
                        }
                        

                        if(state.ziliaoData.list == ''){
                            state.empty_show = true;
                        } else{
                            state.empty_show = false;
                        }

                    } else {
                        proxy.$toast(body.message);
                    }
                })
                .catch(error => console.log(error))
        }

        // 加载更多
        const getMore = () => {
            state.page++;
            getData()
        }

		return {
            state,
            getData,
            getMore
		}
	}
};
</script>

<style scoped lang="scss" src="../../views/detail/index.scss"></style>